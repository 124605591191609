import React from 'react';

const InfoBox = ({ visible, close, onClose, children }) => {
  return (
    <div className={`info-box ${visible ? 'visible' : 'hidden'}`}>
      {close && <button className="close-button" onClick={onClose}>X</button>}
      {children}
    </div>
  );
};

export default InfoBox;