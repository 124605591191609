//import libraries
import React from 'react';

// create a component
const BlockQuoteComponent = (props) => {
    return (
        <div className="blockquote">
           <p>{props.quote}</p>
        </div>
    )
};


//make this component available to the app
export default BlockQuoteComponent;
