//import libraries
import React from 'react';


// create a component
const CustomDividerComponent = (props) => {
    return (
      <>
    {
      props.name === 'fancy' ? (
        <div>
        <hr className="center-ball"></hr>
        <span className="center-ball">&#8230;</span>
        </div>
      ) : (
        <div>
        <hr style={{"width":`${props.width}`, 'height':`${props.height}`, 'backgroundColor':`${props.color}`}}></hr>
        </div>
      )
      }
      </>
      )
    
};

//make this component available to the app
export default CustomDividerComponent;
