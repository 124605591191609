//import libraries
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentWrapper from "../components/Layout/ContentWrapper";
import { confirmSub } from "../actions/mail";

// create a component
const WelcomeConfirmation = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.match.path === "/confirm/:confirmationCode") {
      const code = props.match.params.confirmationCode;
      dispatch(confirmSub(code)).catch((error) =>
        console.log("error in welcome", error)
      );
    }
  }, [dispatch, props.match.params.confirmationCode, props.match.path]);

  return (
    <ContentWrapper>
      <div className="box-content-message">
        <h1>Thank you for confirming!</h1>
        <div>
          <p>
            As an official subscriber, you will receive weekly emails teaching
            you the ins and outs of web design, website security, and web
            development.
          </p>
          <p>
            There’s more! You’ll also get exclusive access to perks like:
            <ul>
              <li>All downloadable content</li>
              <li>First notice of any courses, webinars, or other engagements</li>
              <li>The newest YouTube postings</li>
              <li>My best deals for your website</li>
            </ul>
          </p>
          <p>
            Remember to safelist support@61threetwenty and brad@61ThreeTwenty or
            add it to your address book to ensure our messages do not trigger
            your spam filter.
          </p>
          <p>Happy to have you as a part of the 61ThreeTwenty community!</p>
          <p>
            Best,
            <br />
            brad@61ThreeTwenty
          </p>
        </div>
      </div>
    </ContentWrapper>
  );
};

//make this component available to the app
export default WelcomeConfirmation;
