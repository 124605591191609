//import libraries
import React from 'react';
import { CellPlugin } from "@react-page/editor";
import CustomAmazonAdComponent from "./customComponents/CustomAmazonAdComponent";

type Data = {
  title: string,
  imageUrl: string,
  imageLink: string,
  adType: string,
  customAd: string
};

const AmazonAdComponent: CellPlugin<Data> = {
  Renderer: ({ data }) => (
    <CustomAmazonAdComponent
      imageUrl={data.imageUrl}
      title={data.title}
      imageLink = {data.imageLink}
      adType = {data.adType}
      customAd = {data.customAd}
    />
  ),
  id: "adPlugin",
  title: "Amazon Ad Block",
  description: "Add an ad block to the page",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      properties: {
        title: {
            type: "string",
            default: "Say What",
        },
        imageUrl: {
            type: "string",
        },
        imageLink: {
            type: "string"
        },
        adType: {
            type: "string",
            default: "normal"        
        },
        customAd: {
            type: "string",
        }
      },
      required: ["adType"],
    },
  },
};

export default AmazonAdComponent;