//--- client/scr/actions/tools.js ---
export const SET_TOGGLE = 'SET_TOGGLE';
export const SAVE_CURRENT_POST = "SAVE_CURRENT_POST";
export const SAVE_CURRENT_DESIGN = "SAVE_CURRENT_DESIGN";

const toggleNav = (toggle) => ({
    type: SET_TOGGLE,
    toggle
});

const saveCurrentPost = (post) => ({
    type: SAVE_CURRENT_POST,
    post
})

const saveCurrentDesign = (design) => ({
    type: SAVE_CURRENT_POST,
    design
})

export const setToggle = (toggle) => async dispatch => {
    return dispatch(toggleNav(toggle));
}

export const saveEmailDesign = (design, name, html) => async dispatch => {
    const data = {
        design: design,
        name: name,
        html: html,
        author: 'Brad Hobson'
    }
    fetch('/api/tools/saveEmailDesign', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    })
    
    return dispatch(saveCurrentDesign(design));
}

export const loadDefaultDesign = () => async () => {
    const response = await fetch(`/api/tools/loadDefaultDesign/`)
    if (response.ok) {
        const data = await response.json();
        return data;
    }
}

export const loadSocialSites = () => async () => {
    const response = await fetch(`/api/tools/loadSocialSites/`)
    if (response.ok) {
        const data = await response.json();
        return data;
    }
}

export const loadDesign = (id) => async () => {
    const response = await fetch(`/api/tools/loadDesign/${id}`)
    if (response.ok) {
        const data = await response.json();
        return data;
    }
}


export const getDesignOptions = () => async () => {
    const response = await fetch(`/api/tools/getDesignOptions/`)
   
    if(response.ok) {
        const data = await response.json();
        return data;
    } else {
        return [];
    }
}

export const savePost = (post, userId) => async dispatch => {
    const data = {
        post: post,
        user: userId,
        author: 'Brad Hobson'
    }
    fetch('/api/tools/savepost', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    })
    
    return dispatch(saveCurrentPost(post));
}

export const publishUpdate = (id, value) => async dispatch => {
    const data = {
        id: id,
        publishValue: value
    }
    fetch(`/api/tools/publishUpdate`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    })

    return [];
}


export const loadPopularPosts = () => async () => {
    const response = await fetch(`/api/tools/loadPopularPosts/`)
    
    if (response.ok) {
        const data = await response.json()
        return data;
    } 

    //return error message here
}

export const loadPosts = () => async () => {
    const response = await fetch(`/api/tools/loadposts/`)
    
    if (response.ok) {
        const data = await response.json()
        return data;
    } 

    //return error message here
}

export const loadCategoryPosts = (catId) => async () => {
    const response = await fetch(`/api/tools/loadcategoryposts/${catId}`)
    
    if (response.ok) {
        const data = await response.json()
        return data;
    } 

    //return error message here
}

export const loadSearchResults = (term) => async () => {

    const response = await fetch(`/api/tools/loadsearchresults/${term}`)
    if (response.ok) {
        const data = await response.json()
        return data;
    } 

    //return error message here
}

export const loadPostsAdmin = (user) => async () => {
    
    const response = await fetch(`/api/tools/loadpostsadmin/${user}`)
    
    if (response.ok) {
        const data = await response.json()
        return data;
    } 
    //return error message here
}

export const loadPost = (url) => async () => {
    const response = await fetch(`/api/tools/loadSinglePost/${url}`)
    if (response.ok) {
        const data = await response.json();
        return data;
    }
}

export const loadRelatedPosts = (rposts) => async () => {
    const r = JSON.stringify(rposts);
    const response = await fetch(`/api/tools/loadRelatedPosts/${r}`)
    if (response.ok) {
        const data = await response.json();
        return data;
    }
}

export const countPosts = () => async () => {

    const response = await fetch(`/api/tools/countPosts`)
    if (response.ok) {
        const data = await response.json();
        return data;
    }
}


export const loadTopics = () => async () => {
    const response = await fetch(`/api/tools/topics/`)

    if(response.ok) {
        const data = await response.json();
        return data;
    } else {
        return [];
    }
}

export const loadSocialLinks = () => async () => {
    const response = await fetch(`/api/tools/loadSocialLinks/`)
    if(response.ok) {
        const data = await response.json();
        return data;
    } else {
        return [];
    }
}


export const loadMessages = () => async () => {
    const response = await fetch(`/api/tools/messages/`)
    if(response.ok) {
        const data = await response.json();
        return data;
    } else {
        return [];
    }
}


export const loadSubscribers = () => async () => {
    const response = await fetch(`/api/tools/loadsubscribers/`)
    if(response.ok) {
        const data = await response.json();
        return data;
    } else {
        return [];
    }
}


export const getRelatedPostsOptions = () => async () => {
    const response = await fetch(`/api/tools/getRelatedPostsOptions/`)

    if(response.ok) {
        const data = await response.json();
        return data;
    } else {
        return [];
    }
}

export const getCurrentRelatedPosts = (id) => async () => {
    const response = await fetch(`/api/tools/getCurrentRelatedPosts/${id}`)
    if(response.ok) {
        const data = await response.json();
        
        return data;
    } else {
        return [];
    }
}
export const updateRelatedPosts = (id, options) => async () => {
   
    const optionStr = JSON.stringify(options);
    const data = {
        id,
        options: optionStr
    }
    fetch(`/api/tools/updateRelatedPosts`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    })

    return [];
}


export const addCategory = (category) => async () => {
    
    const data = {name: category}

    fetch(`/api/tools/addCategory`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    })

    return [];
}