//import libraries

export const facebookAPICheck = (token) => {
     // Check if token is valid, if so keep it, if not remove it
    return new Promise(function(resolve, reject){

     window.FB.api(`/me?access_token=${token.toString()}`, function (authResponse) {
        if (!authResponse || authResponse.error) {
          // Token is not valid so we have to remove it 
          reject(authResponse.error)
        } else {
          resolve(authResponse)
        }})
      }
    ).then(val => {return false}).catch(err => {return true})
}