// --- client/src/util/route.js ---
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";

const Auth = ({ path, component: Component }) => {
 const loggedIn = useSelector((state) => state.session.userId);
  return (
    <Route
      path={path}
      render={(props) => {
        return (loggedIn ? <Redirect to="/" /> : <Component {...props} />)
      
      }}
    />
  );
};

const Protected = ({ path, component: Component }) => {
    const loggedIn = useSelector((state) => Boolean(state.session.userId));
  return (
    <Route
      path={path}
      render={(props) =>
        loggedIn ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

//export const AuthRoute = Auth;
export const AuthRoute = withRouter(Auth);
//export const ProtectedRoute = Protected;
export const ProtectedRoute = withRouter(Protected);