import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import configureStore from './store/store';
import { Provider } from 'react-redux';
import { checkLoggedIn } from './util/session';
import registerServiceWorker from './registerServiceWorker';
import './sass/main.scss';

const renderApp = preloadedState => {
    const store = configureStore(preloadedState);
    ReactDOM.hydrate(
      <Provider store={store}>
        <App />
    </Provider>,
      document.getElementById("base_root")
    );
  };

(async () => renderApp(await checkLoggedIn()))();

registerServiceWorker();
