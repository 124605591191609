
export const handleFacebookLogin = () => {
  try {
    // Check if there is a token already and if its valid
    // Check logged in status
    return new Promise(function(resolve, reject){
      window.FB.getLoginStatus(function (response) {
        if (response.status === "not_authorized") {
          reject(response.status)
        } else {
          // not logged in so login
          window.FB.login(
            function (response) {
              if (response.authResponse) {
                const useTokenInFunc = response.authResponse.accessToken; 
                const idInFunc = response.authResponse.userID
                // Good auth token so lets get the account information
                let profileName = '';
                window.FB.api(
                  `/${idInFunc}/accounts`,
                  "GET",
                  { access_token: `${useTokenInFunc.toString()}` },
                  function (res) {
                    if (!res || res.error) {
                      alert("Error occurred getting account");
                      reject(res.error);
                    } else {
                      const  pageName = res.data[0].name;
                      const  pageCategory = res.data[0].category;
                      window.FB.api("/me", function (meResponse) {
                    
                        profileName = meResponse.name;
                        const tokenDetails = {
                          id: idInFunc,
                          site: "Facebook",
                          token: useTokenInFunc,
                          pageName,
                          pageCategory,
                          profileName
                        }
  
                        resolve(tokenDetails);
                      });
                    }
                  }
                );
                // dispatch to save token to user
  
                // STILL NEED TO DO THE WORK FOR LONG LIVED TOKENS
  
               
              } else {
                reject("User cancelled or did not fully authorize")
              }
            },
            {
              scope:
                "pages_manage_posts, pages_show_list, pages_read_user_content, pages_read_engagement",
            }
          );
        }
      });
    }).then(val => {return val}).catch(err => {return null})
   
  } catch (err) {
    console.log("err", err.message);

  }
}