//import libraries
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import BuilderHeader from "../../containers/builder/BuilderHeader";
import BuilderContent from "../../containers/builder/BuilderContent";
import BuilderNavigation from "../../containers/builder/BuilderNavigation";
import CategoryForm from "../../components/UI/CategoryForm";
import Button from "../../components/UI/Button";
import Table from "../../components/UI/Table";
import * as toolActions from '../../actions/tools';

// create Categories Component
const Categories = (props) => {
    const [topic, getTopics] = useState([]);
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState(false);
   
    const columns = useMemo(() => 
    [{
      Header: 'Post Information',
      columns: [
        {
          Header: 'Category Name',
          accessor: 'name'
        },
        {
          Header: 'Document Count',
          accessor: 'documentCount'
        }
      ]
    }], []);
    
    // const Toggle = useCallback(() => {
    //   setModalShow(!modalShow);
    // }, [modalShow]);

    const getTopicData = useCallback(async () => {
      try {
        const insideTopic = await dispatch(toolActions.loadTopics());
        getTopics(insideTopic);
      } catch (err) { 
        console.log('error', err);
      } 
    }, [getTopics, dispatch]);

    useEffect(() => {
      getTopicData();
    }, [getTopicData,topic]);

    const toggleCategoryAddForm = () => {
      setModalShow(true);
    }

    const callbackCloseModal = () => {
      setModalShow(false)
    }

    return (
      <div className="admin__app">
       <CategoryForm show={modalShow} handleModalClose={callbackCloseModal}/>
        <div className="admin__container">
          <div className="admin__container--nav-build">
            <BuilderNavigation />
            <div className="admin__main-container">
              <BuilderHeader />
              <BuilderContent>
                <div className="admin__container-bch">
                  <h1>Categories</h1>
                  <div className="admin__container-bch--controls">
                    <Button
                      type="input"
                      className="btn btn__sub btn__fullWidth u-margin-top-small"
                      text="Add Category"
                      onClick={toggleCategoryAddForm}
                    />
                  </div>
                </div>
                <ul>
                  <Table columns={columns} data={topic} />
                </ul>
              </BuilderContent>
            </div>
          </div>
        </div>
      </div>
    );
};


//make this component available to the app
export default Categories;
