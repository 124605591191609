//import libraries
import React from 'react';
import { CellPlugin } from "@react-page/editor";
import CustomCaptionComponent from "./customComponents/CustomCaptionComponent";

type Data = {
  text: string,
  linkUrl: string,
  linkText: string
};

const CustomCaption: CellPlugin<Data> = {
  Renderer: ({ data }) => (
    <CustomCaptionComponent
      text={data.text}
      linkUrl={data.linkUrl}
      linkText = {data.linkText}
    />
  ),
  id: "caption2Plugin",
  title: "Custom Caption",
  description: "Add a custom caption to the page",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      properties: {
        text: {
          type: "string",
          default: "Original above content image provided by", 
        },
        linkUrl: {
          type: "string",
          default:'/assets/img/default.png'
        },
        linkText: {
          type: "string"
        }
      },
      required: ["text", "linkUrl"],
    },
  },
};

export default CustomCaption;