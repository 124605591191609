//import libraries
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import PostFeature from "../components/PostFeature";
import BuildMetaTags from '../util/MetaTag';
import * as toolActions from "../actions/tools";

// create a component
const Categories = (props) => {
  let { id } = useParams();
  const history = useHistory();
  const [topicName, getTopicName] = useState(history.location.state ? history.location.state.name.name : id);
  const dispatch = useDispatch();
  const [posts, getPosts] = useState([]);
  const metaTags = {
    metatitle: `Category - ${topicName}`,
    metadescription: `61ThreeTwenty post regarding topic ${topicName}.`,
    metacanonical: `categories/${topicName}`,
    metakeywords: `61ThreeTwenty, ${topicName}, web design, security`,
    image: 'https://61threetwenty.com/assets/img/61threetwenty_homepage.png',
    url: `https://61threetwenty.com/categories`
    
};
  // Currently no analytics added in for most popular post
  const fetchData = useCallback(async () => {
    try {
      const postsData = await dispatch(toolActions.loadCategoryPosts(id));
      getTopicName(history.location.state ? history.location.state.name.name : '');
      getPosts(postsData);
    } catch (err) {
      console.log("error", err);
    }
  }, [dispatch, id, history.location.state]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="container clearfix list-container">
    <BuildMetaTags metaTags={metaTags} />
      <div id="content">
        <div className="post-list list">
          <div className="list-layout categories-list">
            <div className="u-margin-top-big">
                <h1 className="content__category--header">Category: <span>{topicName}</span></h1>
              {posts.map((post, index) => (
                <PostFeature key={post.postId} postDetails={post} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//make this component available to the app
export default Categories;
