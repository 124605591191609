//import libraries
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import AboutCard from '../components/AboutCard';
import PostList from "../components/PostList";
import SubscribeBox from "../components/SubscribeBox";
import TopicsCloud from "../components/TopicsCloud";
import AdPlaced from "../components/AdPlaced"
import * as toolActions from "../actions/tools";

// create a component
const SideBar = (props) => {
  const dispatch = useDispatch();
  const [postCount, getPostCount] = useState([]);

  // Currently no analytics added in for most popular post
  const fetchPostCount = useCallback(async () => {
    try {
      const count = await dispatch(toolActions.countPosts());
      getPostCount(count["count"])
    } catch (err) {
      console.log("error", err);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchPostCount();
  }, [fetchPostCount]);

  return (
    <aside id="sidebar">
    { (postCount >= 4) && ( 
    <>
    <AboutCard />
    <SubscribeBox location="homepage" type="sidebar" />
    <AdPlaced source="https://nordvpn.sjv.io/c/3614391/976012/7452" 
    imagelink="//a.impactradius-go.com/display-ad/7452-976012" imghlink="https://nordvpn.sjv.io/i/3614391/976012/7452"
      imgHeight="50%"
      id="976012"
      imgWidth="260"
    />
    <TopicsCloud title="Topics" />
    <div className="theiaStickySidebar u-margin-top-medium">
    <PostList title="Popular Posts" type="popular" amount="6"/></div>
    </>)}</aside>
  );
};

//make this component available to the app
export default SideBar;
