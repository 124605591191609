/* eslint-disable react-hooks/exhaustive-deps */
//import libraries
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import PostAside from "../containers/PostAside";

import {useLocation, NavLink} from 'react-router-dom';
import { loadPost } from '../actions/tools';
import BuildMetaTags from '../util/MetaTag';
import Moment from 'react-moment';

import Editor from '@react-page/editor';
import slate from '@react-page/plugins-slate';
import video from '@react-page/plugins-video';
import spacer from '@react-page/plugins-spacer';
import AmazonAd from '../components/UI/builder/Post/AmazonAd';
import AffiliateBox from '../components/UI/builder/Post/AffiliateBox';
import BlockQuote from "../components/UI/builder/Post/BlockQuote";
import CardChoice from "../components/UI/builder/Post/CardChoice";
import codeSnippet from "../components/UI/builder/Post/CodeSnippet";
import CTABuilder from "../components/UI/builder/Post/CTABuilder";
import CustomDivider from "../components/UI/builder/Post/CustomDivider";
import CustomAd from "../components/UI/builder/Post/CustomAd";
import CustomCaption from "../components/UI/builder/Post/CustomCaption";
import CustomGif from "../components/UI/builder/Post/CustomGif";
import CustomImage from "../components/UI/builder/Post/CustomImage";
import CustomVideo from "../components/UI/builder/Post/CustomVideo";
import PostEntryInfo from "../components/UI/builder/Post/PostEntryInfo";
import RelatedPosts from "../components/RelatedPosts";
import SubscribeBox from "../components/SubscribeBox";
import TopChoiceBlock from "../components/UI/builder/Post/TopChoice";

import '@react-page/editor/lib/index.css';
import '@react-page/plugins-slate/lib/index.css';
import '@react-page/plugins-image/lib/index.css';


// Define which plugins we want to use.
const cellPlugins = [ 
  slate(),
  PostEntryInfo,
  BlockQuote,
  CustomImage,
  CustomDivider,
  spacer,
  CustomCaption,
  codeSnippet,
  CustomAd,
  AffiliateBox,
  AmazonAd,
  CardChoice,
  CTABuilder,
  CustomGif,
  CustomVideo,
  TopChoiceBlock,
  video
];

// create a component
const PostStory = (props) => {
  const {data} = useLocation();
  //const [postUser, setPostUser] = useState(null);
  const [url, setUrl] = useState(data ? data.url : window.location.pathname.replace("/post/", ""));
  const [postInfo, getPostInfo] = useState([]);
  const [topic, setTopic] = useState(postInfo ? postInfo.postTags : ''); 
  const [topicNoSpaces, setTopicsNoSpaces] = useState(null); 
  const [value, setValue] = useState(null);
  const [metaTags, setMetaTags] = useState([]);
  const dispatch = useDispatch();
  const [stickyTOC, setStickyTOC] = useState([]);
  const postRef = useRef(0);
  const [postHeight, setPostHeight] = useState(0);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isVisible, setIsVisible] = useState(false);  
  const avatar = props.avatar ? props.avatar : "portpic_2.jpg";

  useEffect(() => {
    setUrl(data ? data.url : url);
    const urlCheck = url.split('/')[0];
    dispatch(loadPost(urlCheck))
      .then((postData) => {
        getPostInfo(postData);
      const metatags = postData.metatags;
   
      setMetaTags({
        ...metatags,
        url: `https://61threetwenty.com/post/${postData.postTitleUrl}`,
        imageUrl: `https://61threetwenty.com${postData.postImage}` 
      });
      //setPostUser(postData.user)
      setValue(postData.postStory);
      setTopic(postData.postTags);   
      setTopicsNoSpaces(postData.postTags.replace(" ", ""));
      })
      .catch((error) => console.log(error));
  }, [data, url])

  useEffect(() => {
    // Have to clear out the ToCs prior to rebuilding it.
    stickyTOC.map((el, index) => {
     return setStickyTOC(stickyTOC.filter(item => {
        return (el !== item)}))
    });
    traverse(value);
  },[value]);
  
  function traverse(jsonObj) {
    if( jsonObj !== null && typeof jsonObj == "object" ) {
        Object.entries(jsonObj).forEach(([key, value]) => {
            // key is either an array index or object key
            if (value === 'HEADINGS/HEADING-TWO') {
              const checkId = jsonObj;
              if(checkId.hasOwnProperty('data')) {
                const strRplH2 = jsonObj.data.id.replace(' ', '-')
                setStickyTOC(stickyTOC => [...stickyTOC, strRplH2])
              }
            }
            traverse(value);
        });
    }
  }
  
  const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      let widthSubtraction = 300;
      const containerPos = postRef.current.getBoundingClientRect().top;
      const h2CreateActive = document.getElementsByClassName('container')[0] ? document.getElementsByClassName('container')[0].getElementsByTagName('h2') : '';
      // Need to cut down the h2 list in case one does not have an id
      const cutH2List = Object.entries(h2CreateActive).filter((el) => el[1].id !== "");
      const el = document.getElementsByClassName('sticky-TOC')[0] ? document.getElementsByClassName('sticky-TOC')[0].getElementsByTagName('li') : '';
      const progressAmount = (((containerPos * -1) + 485) / postRef.current.getBoundingClientRect().height) * 100;
      const progressbar = document.querySelector('.progress-bar');

      if (containerPos < 100) {
          progressbar.style.width = `${progressAmount}%`;
      } else {
          progressbar.style.width = `0%`;
      }

      if (window.innerWidth > 1310) {
        widthSubtraction = 650;
      }

      for (let i = 0; i < cutH2List.length; i++) {
          if (cutH2List[i][1].getBoundingClientRect().top < 100) {
              for (let j = 0; j < cutH2List.length; j++) {
                  el[j].getElementsByTagName('a')[0].classList.remove('active');
              }
              el[i].getElementsByTagName('a')[0].classList.add('active');
          } 
          
      }

      setIsVisible(
        (containerPos < -200 &&
          currentScrollPos > postHeight - widthSubtraction)
      );

      setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    // Need to do this after getting the data
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, isVisible, handleScroll]);

  useLayoutEffect(() => {
    setPostHeight(postRef.current.clientHeight);
  }, [postRef.current]);

  return (
   
    <div className="blog-post">
     {postInfo.metatags && <BuildMetaTags metaTags={metaTags} /> }
      <div className="blog-post__header-image">
      {postInfo.postImage &&
        <img
          src={`${postInfo.postImage}`}
          className="test"
          alt={`${postInfo.postTitle}`}
          srcSet={`${postInfo.postImage}?zoom=3&amp;resize=500%2C380&amp;ssl=1 1500w`}
        />
      }
      </div>
      <div className="container container--width justify--sb-i" ref={postRef}>
        <article className="blog-post__article-content">
          <h1 className="blog-post__article-content-title">
            {postInfo.postTitle}
          </h1>
          <div className="blog-post__article-content-author">
            <ul>
              <li>
                  <img
                    className="blog-post__article-content-author--avatar"
                    src={`/assets/img/${avatar}`} alt="avatar"
                  />
              </li>
              <li>
                <span>
                  by  <i>{postInfo.postAuthor}</i> 
                </span>
              </li>
              <li>
                <i className="fa fa-calendar" aria-hidden="true"></i> <Moment format="MMM Do YYYY" date={postInfo.updatedAt} />
              </li>
              <li>
                <i className="fa fa-clock-o" aria-hidden="true"></i> {postInfo.readLength}
              </li>
              
              <li>
                <NavLink 
                to={{
                            pathname: `/categories/${topicNoSpaces}/`,
                            state: {
                              item: { topicNoSpaces },
                              name: { topic },
                            },
                          }}>{postInfo.postTags}</NavLink>
              </li>
            </ul>
          </div>
          
          <div
            className="blog-post__article-content-text">
          <Editor cellPlugins={cellPlugins} value={value} readOnly /></div>
          {stickyTOC.length > 0 ? (
          <ul className={`sticky-TOC ${isVisible ? "is-visible" : "not-visible"}`}>
          <h4>Table of Contents</h4>
            {stickyTOC.map((el, index) => {
              const noDashValue = el.replaceAll('-', ' ');
              return (
                <li key={index}>
                  <a href={`#${el}`}>{noDashValue}</a>
                </li>
              )
            })}
          </ul>) : ""}
          
          <RelatedPosts ids={postInfo.relatedPostIds} />
          {/* <ContactUsCard location={url} /> */}
          <div className="subscribe-box--coat">
            <SubscribeBox location={postInfo.postTitleUrl} type="post"  />
          </div>
        </article>
        <PostAside location={postInfo.postTitleUrl} />
      </div>
    </div>
  );
};

//make this component available to the app
export default PostStory;
