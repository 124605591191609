//import libraries
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import PostFeature from "../components/PostFeature";
import * as toolActions from "../actions/tools";
import BuildMetaTags from '../util/MetaTag';

// create a component
const SearchResults = (props) => {
  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");

  const dispatch = useDispatch();
  const [posts, getPosts] = useState([]);
  const metaTags = {
    metatitle: `Search Results`,
    metadescription: `61ThreeTwenty search results.`,
    metacanonical: `search-results`,
    metakeywords: `61ThreeTwenty, search results`,
    image: 'https://61threetwenty.com/assets/img/61threetwenty_homepage.png',
    url: `https://61threetwenty.com/search-results/`
  };

  // Currently no analytics added in for most popular post
  const fetchData = useCallback(async () => {
    try {
      const postsData = await dispatch(toolActions.loadSearchResults(query));
      getPosts(postsData);
    } catch (err) {
      console.log("error", err);
    }
  }, [dispatch, query]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="container clearfix list-container">
      <BuildMetaTags metaTags={metaTags} />
      <div id="content">
        <div className="post-list list">
          <div className="list-layout">
            <div className="u-margin-top-big">
              <div className="flex__container justify--c"><h1>Results for: <span>{query}</span></h1></div>
              {posts.map((post, index) => (
                <PostFeature key={post.postId} postDetails={post} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//make this component available to the app
export default SearchResults;
