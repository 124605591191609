import React from "react";
import AdPlaced from '../components/AdPlaced';
import PostList from "../components/PostList";
import SubscribeBox from "../components/SubscribeBox";

const PostAside = (props) => {
  return (
    <div className="post-aside">
      <AdPlaced
        source="https://namecheap.pxf.io/c/3614391/1184515/5618"
        id="1184515"
        imagelink="//a.impactradius-go.com/display-ad/5618-1184515"
        imgWidth="336"
        imgHeight="281"
        imghlink="https://namecheap.pxf.io/i/3614391/1184515/5618"
      />
      <PostList title="Popular Posts" type="popular" amount="6" />
      <SubscribeBox location={props.location} type="sidebar"  />
    </div>
  );
};

//make this component available to the app
export default PostAside;
