import React from "react";

// Custom component to render Tag 
const Tags = ({ values }) => {
      return (
        <>
         
              <span className="badge">
                {values}
              </span>
         </>
      );
    };

 export default Tags;