//import libraries
import React, {useState, useEffect} from "react";
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import * as toolActions from '../../actions/tools';

// create a component
const RelatedPostBuilder = (props) => {
    const [options, getOptions] = useState([]);
    const [selectedOptions, setSelectedOption] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
          try {
            const optionsX = await dispatch(toolActions.getRelatedPostsOptions());            
            getOptions(optionsX);
            const currentSelected = await dispatch(toolActions.getCurrentRelatedPosts(props.postId));
            setSelectedOption(currentSelected.relatedPostIds)
          } catch (err) { 
            console.log('error', err);
          } 
        })();
    }, [getOptions, setSelectedOption, dispatch, props.postId]);
    
    const handleSelectedChange = (so) => {
        (async () => {
            try {
              setSelectedOption(so);
              await dispatch(toolActions.updateRelatedPosts(props.postId, so));            
            } catch (err) { 
              console.log('error', err);
            } 
          })();
    };

    return (
            <Select 
                isMulti
                options={options} 
                isClearable
                isSearchable
                value={selectedOptions}
                className="post-builder--select"
                onChange={(e) => handleSelectedChange(e)}
            />
        
    )
};


//make this component available to the app
export default RelatedPostBuilder;
