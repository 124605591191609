//import libraries
import React from 'react';
import { CellPlugin } from "@react-page/editor";

type Data = {
  websites: string,
};

const AffiliateBox: CellPlugin<Data> = {
  Renderer: ({ data }) => (
    <div className="box--affiliate"><p>*Disclosure: This post contains links to 3rd party companies listed below. 
    Clicking these links I may receive a small commission when you buy something at no cost to you. Thanks. </p>
    <span>{data.websites}</span> </div>
  ),
  id: "affiliatePlugin",
  title: "Affiliate Block",
  description: "Add an affiliate disclosure to the page",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      properties: {
        websites: {
            type: "string",
            default: "",
        },
      },
      required: [],
    },
  },
};

export default AffiliateBox;