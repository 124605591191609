//import libraries
import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadTopics } from '../actions/tools';

// create a component
const TopicsCloud = (props) => {
    //const topics = props.topics;
    const [topics, getTopics] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(loadTopics())
        .then((data) => {
          data.map((el, index) => {
            return getTopics(data.filter((item) => {
              return (item.publishedCount > 0)
            }))
          })
        })
        .catch((error) => console.log(error));
    }, [getTopics, dispatch]);

    return (
      <div className="widget">
        <h4 className="widget-title"><span>{props.title}</span></h4>
        <div className="widget-topic--wrap">
        {topics.map((topic) => {
          const topicnospaces = topic.name.replace(" ", "");
          const urlstring = `/categories/${topicnospaces}/`;
           const name = topic.name;
          return (
            <span className="topic" key={topicnospaces}>
              <NavLink
                to={{
                  pathname: `${urlstring}`,
                  state: {
                    item: { topicnospaces },
                    name: { name },
                  },
                }}
                className="link--size-small"
              >
                {topic.name}
              </NavLink>
            </span>
          );
        })}
        </div>
      </div>
    );
};


//make this component available to the app
export default TopicsCloud;
