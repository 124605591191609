// --- client/src/pages/Dashboard.js ---
import React from "react";

import BuilderHeader from "../../containers/builder/BuilderHeader";
import BuilderContent from "../../containers/builder/BuilderContent";
import BuilderNavigation from "../../containers/builder/BuilderNavigation";

const Dashboard = (props) => {
  return (
    <div className="admin__app">
      <div className="admin__container">
        <div className="admin__container--nav-build">
          <BuilderNavigation />
          <div className="admin__main-container">
            <BuilderHeader />
            <BuilderContent>
              <div>DASHBOARD</div>
            </BuilderContent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
