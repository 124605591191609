//import libraries
import React from 'react';
import { CellPlugin } from "@react-page/editor";
import PostEntryComponent from "../../../PostEntryComponent";

// use a type here, not an interface
type Data = {
  title: string,
  tags: string,
  blurb: string,
  readLength: string,
  width: integer,
  height: integer,
  video: string,
  meta: {
     metatitle: string,
     metadescription: string,
     metacanonical: string,    
     metakeywords: string
  }
};

const PostEntryInfo: CellPlugin<Data> = {
  Renderer: ({ data }) => <PostEntryComponent data={data} />,
  id: "postEntryInfo",
  title: "Post Entry Information",
  description: "Build Information about the post",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      properties: {
        blurb: {
          type: "string",
          default: "Put in your search blurb here",
        },
        tags: {
          type: "string",
        },
        readLength: {
          type: "string"
        },
        metatitle: {
          type: "string"
        },
        metadescription: {
          type: "string"
        },
        metacanonical: {
          type: "string"
        },
        metakeywords: {
          type: "string"
        },
      },
    },
  },
};

//make this component available to the app
export default PostEntryInfo;
