//import libraries
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch } from 'react-redux';
import Moment from 'react-moment';
import BuilderHeader from "../../containers/builder/BuilderHeader";
import BuilderContent from "../../containers/builder/BuilderContent";
import BuilderNavigation from "../../containers/builder/BuilderNavigation";
import Table from "../../components/UI/Table";
import * as toolActions from '../../actions/tools';

// create Messages Component
const Messages = (props) => {
    const [messages, getMessages] = useState([]);
    const dispatch = useDispatch();

    const getMessagesData = useCallback(async () => {
      try {
        const insideMessage = await dispatch(toolActions.loadMessages());
        getMessages(insideMessage);
      } catch (err) { 
        console.log('error', err);
      } 
    }, [getMessages, dispatch]);

    useEffect(() => {
        getMessagesData();
    }, [getMessagesData]);

    const columns = useMemo(() => 
    [{
        Header: 'Messages',
        columns: [
        {
            Header: 'From',
            accessor: 'name'
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Message',
            accessor: 'info',
        },
        {
            Header: 'Kickoff Sent',
            accessor:'kickoff'
        },
        {
            Header: 'Weekly',
            accessor: 'weekly'
        },
        {
            Header: 'Last Update',
            accessor: 'updatedAt',
            Cell: ({ cell: { value } }) => <Moment format="MM-DD-YYYY" date={value} />
        },

        ]
    }], []);

    return (
        <div className="admin__app">
      <div className="admin__container">
        <div className="admin__container--nav-build">
          <BuilderNavigation />
          <div className="admin__main-container">
            <BuilderHeader />
            <BuilderContent>
              <Table columns={columns} data={messages} />
            </BuilderContent>
          </div>
        </div>
      </div>
    </div>
    );
};


//make this component available to the app
export default Messages;
