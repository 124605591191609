//import libraries
import React from 'react';
import { NavLink } from 'react-router-dom';

// create a component
const Copyright = (props) => {
    return (
        <div className="copyright" align="center">
          {'Copyright © '}
          <NavLink color="inherit" to="/">
           61threetwenty.com
          </NavLink>{' '}
          {new Date().getFullYear()}
          {'.'}
        </div>
      );
};



//make this component available to the app
export default Copyright;
