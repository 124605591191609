//import libraries
import React, { useState} from 'react';
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ContentWrapper from "../components/Layout/ContentWrapper";
import { unsubscribe } from "../actions/mail";
import Button from "../components/UI/Button";

// create a component
const Unsubscribe = (props) => {
    const { code } = useParams();
    const dispatch = useDispatch();
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            await dispatch(unsubscribe(code));
            setSubmitted(true)
        } catch (err) {
          console.log("ERROR:", err.message);
        }
      };
    return (
        <ContentWrapper>
            <h1>Communication Preferences</h1>
            <div className="u-padding">If you did not intend to unsubscribe, please ignore this page.</div>
            {submitted ? (
                <div>I am definitely sorry to see you go from my newsletters, but you are still welcome to view and search the website for content that interested.</div>
            ) :
            (
            <div>
                <Button 
                    type="submit"
                    variant="contained"
                    className="btn btn__sub u-margin-top-small"
                    text="Unsubscribe me from all mailing lists."
                    onClick={handleSubmit}    
                    />
            </div>)}
        </ContentWrapper>
    )
};



//make this component available to the app
export default Unsubscribe;
