//import libraries
import React from 'react';
import { NavLink } from 'react-router-dom';

// create a component
const AboutCard = (props) => {
    return (
        <div className="flex-box--column">
            <h3>Hi and welcome!</h3>
            <div className="u-margin-top-small">
            <img className="blog-post__article-content-author--avatar--homepage" src="/assets/img/portpic_2.jpg" alt="Brad" />
                
                <p>My name is <i>Brad</i>. I have over 20 years experience in web design with experience in project management and IT security. 
                This site is me getting my experience down on "paper". Hopefully some of it makes sense and helps you.
                <NavLink 
                    to="/about"> Read more</NavLink>
                </p>
            </div>
        </div>
    )
};


//make this component available to the app
export default AboutCard;
