//--- client/src/reducers/session/tools.js ---
import {
    SET_TOGGLE,
    SAVE_CURRENT_POST,
    SAVE_CURRENT_DESIGN
} from '../../actions/tools';

const _initialTools = { toggle: false, currentPost: null }

export default (state = _initialTools, action) => {
    Object.freeze(state);
    switch (action.type) {
        case SET_TOGGLE:
            return {
                ...state,
                toggle: !action.toggle
            };
        case SAVE_CURRENT_POST:
            return {
                ...state,
                currentPost: action.post
            };
        case SAVE_CURRENT_DESIGN:
            return {
                ...state,
                currentPost: action.design
            };
        default:
            return state;
    }
};