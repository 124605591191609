//import libraries
import React from 'react';
import { CellPlugin } from "@react-page/editor";
import BlockQuoteComponent from "./customComponents/BlockQuoteComponent";

type Data = {
    type: String,
    quote: String
};

const BlockQuote: CellPlugin<Data> = {
  Renderer: ({ data }) => (
    <BlockQuoteComponent
      type={data.type}
      quote={data.quote}
    />
  ),
  id: "blockquoteplugin",
  title: "BlockQuote",
  description: "Add a block quote to the page",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      properties: {
        type: {
          type: "string",
          default: "original",
        },
        quote: {
          type: "string",
        }
      },
      required: ["type"],
    },
  },
};

export default BlockQuote;