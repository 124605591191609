//import libraries
import React from 'react';
import HomeContent from '../containers/HomeContent';

// create a component
const Post = (props) => {
    return (
        <div>
            
            <main>
                <div className="main-container-wrapper">
                    <HomeContent postClick={true} />
                </div>
            </main>
        </div>
    )
};


//make this component available to the app
export default Post;
