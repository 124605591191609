//import libraries
import React from 'react';

// create a component
const TopChoiceComponent = (props) => {

    return (
        <div className="tCB">
        <div className="tCB__inner--wrap">
        <div>
            <div className="tCB__best"><div className="tCB__best--wrap"><a href={`${props.siteUrl}`}>{props.bestFor}</a></div></div>
            <div className="spacer"></div>
            <div className="tCB__PIU"><div className="tCB__PIU--wrap"><div className="tCB__PIU--wrap--2"><a href={`${props.siteUrl}`}>
            <img src={`${props.productImageUrl}`} alt={`${props.title}`} /></a></div></div></div>
            <h4 className="text-align--center">{props.title}</h4>
            <div className="spacer"></div>
            <p className="text-align--center tCB__para">{props.paragraph}</p>
            </div><div>
            <div className="tCB__SU"><div className="tCB__SU--wrap"><a href={`${props.siteUrl}`}>Go to site</a></div></div>
            <div className="tCB__RA"><a href={`#${props.reviewAnchor}`}>Read More</a></div>
            </div>
        </div>
        </div>
    )
};

//make this component available to the app
export default TopChoiceComponent;