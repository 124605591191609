//import libraries
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Input from "../UI/Input";
import Button from "../UI/Button";
import { subscribe } from "../../actions/session";
import Cookies from 'universal-cookie';
 

// create a component
const Modal = ({ show, close, form, loc, thruType }) => {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const cookies = new Cookies();


  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const subscriber = {
      email: e.target[0].value,
      location: loc,
      type: thruType,
      subject: "Welcome to 61ThreeTwenty", // Subject line
      text: "Hello world test 2?", // plain text body
      html: '', // html body
    };

    const action = subscribe(subscriber);

    try {
      await dispatch(action);
      setSubmitted(true);
      cookies.set('subscribed61320', true, { path: '/' });
    } catch (err) {
      console.log("ERROR:", err.message);
    }
  };

  const handleClose = (e) => {
    setShowModal(!show);
    document.body.style.overflow = "visible"; 
  };
  return (
    <>
      {showModal ? (
        <div className="modalBox">
          <div className="modalBox__content">
            <div className="modalBox__content--info">
              {submitted ? (
                <>
                  <h2>Thank you</h2>
                  <p>Look for the confirmation email in your in your inbox!</p>
                  <span
                    className="btn__sub form-btn"
                    onClick={(e) => handleClose(e)}
                  >
                    Close
                  </span>
                </>
              ) : (
                <>
                  <h2>Save Your Day</h2>
                  <p>Get all of our new information in your inbox.</p>
                  <form onSubmit={handleSubmit}>
                    <Input
                      variant="light"
                      margin="none"
                      required
                      fullWidth
                      name="email"
                      label="Email Address"
                      type="email"
                      id="subscribeEmail"
                      autoComplete="email"
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      className="btn btn__sub btn__fullWidth u-margin-top-small"
                      text="Sign Up"
                    >
                      Sign Up
                    </Button>
                  </form>
                  <span><i>I promise not to spam ever!</i></span>
                </>
              )}
            </div>
            <div>
              <span
                className="modalBox__content--close"
                onClick={(e) => handleClose(e)}
              >
                &times;
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

//make this component available to the app
export default Modal;
