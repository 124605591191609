//import libraries
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

// create a component
const ScrollToTop = (props) => {
    const { pathname } = useLocation();
 useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};



//make this component available to the app
export default ScrollToTop;
