//import libraries
import React, {useState} from 'react';
import { Link } from 'react-router-dom';

// create a component
const IconMark = ({value, different, postDetails}) => {
    const [checked] = useState(value ? 1 : 0);
    // const handleChecked = () => {
    //     setChecked(!checked);
    // }
   // const postInfo = postDetails ? postDetails : 'nodda';

    return (

        <>
        {different === 'publish' ?
        (checked ? (<><i className="fas fa-circle green"></i> <span>Active</span></>
        ) : (<><i className="fas fa-circle red"></i> <span>Not Published</span></>
        )) :
        (

            <span><Link to={{pathname: `/builder/create/`, data: {id: postDetails.postId, story: postDetails.postStory, publishState: postDetails.publishedPost }}}
                      key={postDetails.postId}><i className="fas fa-eye green"></i></Link></span>
        ) 
        }
         </>
    )
};

//make this component available to the app
export default IconMark;
