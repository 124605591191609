import React, { useState, useReducer, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Avatar from "../components/UI/Avatar";
import Button from "../components/UI/Button";
import Input from "../components/UI/Input";
import Checkbox from "../components/UI/Checkbox";
import FormControlLabel from "../components/UI/FormControlLabel";
import Copyright from "../components/Copyright";
import { signup } from "../actions/session";

const FORM_INPUT_UPDATE = "FORM_INPUT_UPDATE";

const formReducer = (state, action) => {
  if (action.type === FORM_INPUT_UPDATE) {
    const updatedValues = {
      ...state.inputValues,
      [action.input]: action.value
  }
  const updatedValidities = {
      ...state.inputValidities,
      [action.input]: action.isValid
  }
  let updatedFormIsValid = true;
  for (const key in updatedValidities) {
      updatedFormIsValid = updatedFormIsValid && updatedValidities[key];
  }
  return {
      //...state, // not needed as we are updated all state items below
      formIsValid: updatedFormIsValid,
      inputValidities: updatedValidities,
      inputValues: updatedValues
  };
  }
  return state;
};

const SignUp = () => {
  const history = useHistory();
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      username: "",
      email: "",
      password: "",
    },
    inputValidities: {
      username: true,
      email: true,
      password: true,
    },
    formIsValid: true,
  });
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    extra: false,
  });

  const handleChecked = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = {
      username: formState.inputValues.username,
      email: formState.inputValues.email,
      password: formState.inputValues.password,
      promotions: state.extra
    }
    const action = signup(user);
    try {
      const userEmail = await dispatch(action);

      //Implement validation pin here 
      history.push(`/confirmation/${userEmail}`);
    } catch (err) {
      console.log("ERROR:", err.message);
    }
  };

  const inputChangeHandler = useCallback(
    (inputIdentifier, inputValue, inputValidities) => {
      dispatchFormState({
        type: FORM_INPUT_UPDATE,
        input: inputIdentifier,
        value: inputValue,
        isValid: true,
      });
    },
    [dispatchFormState]
  );

  return (
    <div className="container container-Xs">
      <div className="paperTheme">
        <Avatar type="circle" bgcolor="secondary">
          <i className="fas fa-lock"></i>
        </Avatar>
        <h5>Sign up - Currently not accepting sign-ups</h5>
        {errors && <div className="error"><span className="red">{errors}</span></div>}
        <form onSubmit={handleSubmit}>
          <Input
            autoComplete="username"
            margin="normal"
            name="username"
            variant="outlined"
            required
            fullWidth
            id="username"
            label="Username"
            autoFocus
            onInputChange={inputChangeHandler}
          />

          <Input
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onInputChange={inputChangeHandler}
          />

          <Input
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onInputChange={inputChangeHandler}
          />

          <FormControlLabel
            control={
              <Checkbox
                name="extra"
                className="hidebox-1"
                value="allowExtraEmails"
                checked={state.extra}
                onChange={handleChecked}
              />
            }
            color="primary"
            checked={state.extra}
            label="I want to receive inspiration, marketing promotions and updates via email."
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled
            className="btn btn__sub btn__fullWidth u-margin-top-small"
            text="Sign Up"
          >
            Sign Up
          </Button>
          <div className="u-margin-top-small flex__container">
            <div className="flex--xs-left">
              
            </div>
          </div>
        </form>
      </div>
        <Copyright />
    </div>
  );
};

export default SignUp;
