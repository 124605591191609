//import libraries
import React from 'react';

// create a component
const Checkbox = (props) => {
    return (
        <input type="checkbox" {...props} />
    )
};



//make this component available to the app
export default Checkbox;
