import React from "react";
import { NavLink } from "react-router-dom";
import PostList from "../components/PostList";
import TopicsCloud from "../components/TopicsCloud";
import SocialFollowLinks from "../components/Social-links";
const Footer = () => {

    if (window.location.pathname === '/login') return null;

  
  return (
    <footer>
      <div className="col-3__wrapper color--white footer--large">
        <div className="col-3__col">
          <div className="u-margin-top-small">
            <span className="logo-text">Thanks Again!</span>
            <p>
              For browsing our site. If you have any questions once again feel
              free to  <NavLink
              to="/contact"
              className=""
            >
              contact us
            </NavLink>
            </p>
          </div>
          <div><h3>Follow us on one of these platforms</h3>
          
          <SocialFollowLinks />
          </div>
        </div>
        <div className="col-3__col">
          <PostList title="Recent Posts" direction='column' postId="footer_post_widget" type="recent" amount="4" />
        </div>
        <div className="col-3__col">
          <TopicsCloud title="topics" />
        </div>
      </div>
      <div className="footer-links-wrapper">
        <ul className="footer-nav__list">
          <li className="footer-nav__item">
            <NavLink
              to="/"
              className="footer-nav__link link--size-xx-small color--white"
            >
              Home
            </NavLink>
          </li>
          <li className="footer-nav__item">
            <NavLink
              to="/"
              className="footer-nav__link link--size-xx-small color--white"
            >
              Articles
            </NavLink>
          </li>
          <li className="footer-nav__item">
            <NavLink
              to="/about"
              className="footer-nav__link link--size-xx-small color--white"
            >
              About
            </NavLink>
          </li>
          <li className="footer-nav__item">
            <NavLink
              to="/contact"
              className="footer-nav__link link--size-xx-small color--white"
            >
              Contact
            </NavLink>
          </li>
        </ul>
        <ul className="footer-nav__list">
          <li className="footer-nav__item">
            <NavLink
              to="/privacy"
              className="footer-nav__link link--size-xx-small color--white"
            >
              Privacy Policy
            </NavLink>
          </li>
          <li className="footer-nav__item">
            <NavLink
              to="/termsofuse"
              className="footer-nav__link link--size-xx-small color--white"
            >
              Terms of Use
            </NavLink>
          </li>
          <li className="color--white">&#169;2022 61ThreeTwenty</li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
