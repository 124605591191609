//import libraries
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Input from "../UI/Input";
import Button from "../UI/Button";
import { addCategory } from "../../actions/tools";

// create a component
const CategoryModalForm = (props) => {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(true);
  
  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const categoryName = e.target[0].value;

    const action = addCategory(categoryName);

    try {
      await dispatch(action);
      setSubmitted(true);
    } catch (err) {
      console.log("ERROR:", err.message);
    }
  };

  const handleClose = (e) => {
    setShowModal(!props.show);
    props.handleModalClose();
  };
  return (
    <>
      {showModal ? (
      <div className="modalBox">
        <div className="modalBox__content">
          <div className="modalBox__content--info">
            {submitted ? (
              <>
                <h2>Category Added</h2>
                <span
                  className="btn__sub form-btn"
                  onClick={(e) => handleClose(e)}
                >
                  Close
                </span>
              </>
            ) : (
              <>
                <h2>Category</h2>
                <form onSubmit={handleSubmit}>
                  <Input
                    variant="light"
                    margin="none"
                    required
                    fullWidth
                    name="email"
                    label="Category Name"
                    type="text"
                    id="CategoryName"
                    autoComplete="text"
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="btn btn__sub btn__fullWidth u-margin-top-small"
                    text="Add +"
                  />
                </form>
              </>
            )}
          </div>
          <div>
            <span
              className="modalBox__content--close"
              onClick={(e) => handleClose(e)}
            >
              &times;
            </span>
          </div>
        </div>
      </div>
      ) : null}
    </>
  );
};

//make this component available to the app
export default CategoryModalForm;
