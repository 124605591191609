//import libraries
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import SettingsCard from "../components/UI/SettingsCard";
import InfoBox from "../components/UI/InfoBox";
import SocialBox from "../components/UI/SocialBox";
import * as toolActions from "../actions/tools";
import * as sessionActions from "../actions/session";

import { facebookAPICheck } from "../util/social/socialAPICheck";

// create a component
const SocialNetworksSettings = (props) => {
  const dispatch = useDispatch();
  const [socialSites, setSocialSites] = useState(null);
  const userId = useSelector((state) => state.session.userId);
  const userSettings = useSelector((state) => state.session.settings);
  const [isClicked, setIsClicked] = useState(false);
  const [infoBoxVisible, setInfoBoxVisible] = useState(false);
  const [socialBoxVisible, setSocialBoxVisible] = useState(false);

  const getSocialDefaultSettings = useCallback(async () => {
    try {
      let linkInfo = {};
      linkInfo = await dispatch(toolActions.loadSocialSites());
      setSocialSites({ socialSites: linkInfo });
    } catch (err) {
      console.log("error", err);
    }
  }, [dispatch]);

  const callme2 = () => {
    console.log("hey! i was called as well");
  };

  const checkSocialConnections = useCallback(async () => {
    try {
      // Here we loop through socialTokens and get that information
      if (userSettings.socialsettings.socialTokens) {
        userSettings.socialsettings.socialTokens.map((token) => {
          switch (token.name) {
            case "Facebook":
              facebookAPICheck(token.token).then(function (result) {
                if (result) {
                  dispatch(sessionActions.removeSocialToken(userId, token.userId, token.token));
                }
                return null;
              });
            break;  
            default:
              callme2();
              break;
          }
        });
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  }, [dispatch, userSettings.socialsettings, userId]);

  useEffect(() => {
    getSocialDefaultSettings();
    checkSocialConnections();
    //    const alreadySet = userSettings ? userSettings.socialsettings.socialSites : null
    // if(!alreadySet) {
    //checkUserPreferences();
    //}
  }, [getSocialDefaultSettings, checkSocialConnections]);

  const toggleInfoBox = () => {
    setInfoBoxVisible(!infoBoxVisible);
  };

  const hideInfoBox = () => {
    setInfoBoxVisible(false);
  };

  const toggleSocialBox = () => {
    setIsClicked(true);
    setSocialBoxVisible(!socialBoxVisible);
  };

  const hideSocialBox = () => {
    setSocialBoxVisible(false);
  };

  //const onSaveHandler = async () => {
  // setIsSaving(true);

  // await dispatch(sessionActions.saveSocialPreferences(userId, userPreferences));
  //   setTimeout(() => setIsSaving(false), 2000);
  //};
  return (
    <div>
      <div>
        <SettingsCard>
          <div className="flex--inline justify--sb">
            <div className="flex--inline justify--sb">
              <h3>Social Networks</h3>
              <button
                className="txt-btn u-margin-left-1 link--size-small"
                onClick={toggleInfoBox}
              >
                Show Info
              </button>
              <InfoBox
                visible={infoBoxVisible}
                close={true}
                onClose={hideInfoBox}
              >
                <h1>Social Share Information</h1>
                <p className="paragraph--note-size">
                  Connecting social networks allows you to set up posting your
                  blog post to those networks. Without a connection the
                  auto-post and schedule posts features will not work.
                  <br />
                  <br />
                  For networks the only permissions asked for are those related
                  to publishing your article to the network which you will be
                  able to modify first as well as features to track social
                  engagement so that you can see those in your metrics
                  dashboard.
                </p>
              </InfoBox>
            </div>
            <button className={`btn__root btn__sub`} onClick={toggleSocialBox}>
              <span className="weight--bold link--size-medium u-margin-right-1">
                +
              </span>
              Create New Profile
            </button>
            {isClicked && (
              <SocialBox
                visible={socialBoxVisible}
                close={true}
                onClose={hideSocialBox}
                socialSites={socialSites}
              />
            )}
          </div>
          <div>
            {/* CHECK FOR Social Connections */}

            {socialSites &&
              userSettings.socialsettings.socialTokens &&
              userSettings.socialsettings.socialTokens.map((acct, index) => {
                const socialSite = socialSites.socialSites.find(
                  (o) => o.name === acct.name
                );
                return (
                  <div key={index} id={index}>
                    <div className="flex--inline justify--sb">
                      <div className="flex--inline flex--direction-col">
                        <div className="flex--inline">
                          <span className="w--5">
                            <i
                              className={socialSite.icon}
                              style={{
                                color: `${socialSite.color}`,
                                background: `${socialSite.bgColor}`,
                                fontSize: "3.6rem",
                                padding: "2px",
                              }}
                            ></i>
                          </span>
                          <h4>{acct.name}</h4>
                        </div>
                        <div className="font--size-small color--grey">
                          <span className="weight--bold color--secondary u-margin-right-1">Profile:</span>
                          {acct.profileName}
                          <button className="btn btn__link u-margin-left-1 color--secondary">Delete</button>
                        </div>
                        <div className="font--size-small color--grey">
                          <span className="weight--bold color--secondary u-margin-right-1">Page:</span>
                          {acct.pageName} | <span className="weight--bold color--secondary u-margin-right-1">Category:</span>
                          {acct.pageCategory}
                          <button className="btn btn__link u-margin-left-1 color--secondary">Delete</button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </SettingsCard>
      </div>
    </div>
  );
};

//make this component available to the app
export default SocialNetworksSettings;
