//import libraries
import React from 'react';
import { CellPlugin } from "@react-page/editor";
import CustomGifComponent from "./customComponents/CustomGifComponent";

type Data = {
  sourceUrl: string,
  giphyUrl: string,
  imgText: string,
};

const CustomGif: CellPlugin<Data> = {
  Renderer: ({ data }) => (
    <CustomGifComponent
      sourceUrl={data.sourceUrl}
      giphyUrl={data.giphyUrl}
      imgText={data.imgText}
    />
  ),
  id: "gif2Plugin",
  title: "GIF",
  description: "Add a GIPHY to the page",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      properties: {
        sourceUrl: {
          type: "string",
          default:'https://test.com/'
        },
        giphyUrl: {
            type: "string",
            default:'https://test.com/'
          },
          imgText: {
            type: "string",
            default:'https://test.com/'
          },
      },
    },
  },
};

export default CustomGif;