//import libraries
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch } from 'react-redux';
import Moment from 'react-moment';
import BuilderHeader from "../../containers/builder/BuilderHeader";
import BuilderContent from "../../containers/builder/BuilderContent";
import BuilderNavigation from "../../containers/builder/BuilderNavigation";
import Table from "../../components/UI/Table";
import * as toolActions from '../../actions/tools';

const Subscribers = () => {
    const [subscribers, getSubscribers] = useState([]);
    const dispatch = useDispatch();

    const getSubscribersData = useCallback(async () => {
      try {
        const insideSubscribers = await dispatch(toolActions.loadSubscribers());
        getSubscribers(insideSubscribers);
      } catch (err) { 
        console.log('error', err);
      } 
    }, [getSubscribers, dispatch]);

    useEffect(() => {
        getSubscribersData();
    }, [getSubscribersData]);

    const columns = useMemo(() => 
    [{
        Header: 'Subscribers',
        columns: [
        {
            Header: 'Email',
            accessor: 'email'
        },
        {
            Header: 'Sub Location',
            accessor: 'location',
        },
        {
            Header: 'Type',
            accessor: 'type',
        },
        {
            id: 'optIn',
            Header: 'Opted In',
            accessor: d => d.optIn.toString()
        },
        {
            Header: 'Preference',
            accessor: 'emailPreference'
        },
        {
            Header: 'Sent Emails',
            accessor: 'sentEmailCount',
        },
        {
            Header: 'Interacted Emails',
            accessor: 'openEmailCount',
        },
        {
            Header: 'Subscribed Date',
            accessor: 'subscriptionConfirmDate',
            Cell: ({ cell: { value } }) => <Moment format="MM-DD-YYYY" date={value} />
        },
        {
            Header: 'Last Sent Email',
            accessor: 'lastSentEmail',
            Cell: ({ cell: { value } }) => <Moment format="MM-DD-YYYY" date={value} />
        }
        ]
    }], []);


  return (
    <div className="admin__app">
      <div className="admin__container">
        <div className="admin__container--nav-build">
          <BuilderNavigation />
          <div className="admin__main-container">
            <BuilderHeader />
            <BuilderContent>
              <Table columns={columns} data={subscribers} />
            </BuilderContent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribers;
