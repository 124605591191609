//import libraries
import React, { useReducer, useState, useCallback } from "react";
import ReactGA from 'react-ga4';
import { useDispatch } from "react-redux";
import Input from '../../../../../components/UI/Input';
import { subscribe } from '../../../../../actions/session';


const FORM_INPUT_UPDATE = "FORM_INPUT_UPDATE";

const formReducer = (state, action) => {
  if (action.type === FORM_INPUT_UPDATE) {
    const updatedValues = {
      ...state.inputValues,
      [action.input]: action.value
  }
  const updatedValidities = {
      ...state.inputValidities,
      [action.input]: action.isValid
  }
  let updatedFormIsValid = true;
  for (const key in updatedValidities) {
      updatedFormIsValid = updatedFormIsValid && updatedValidities[key];
  }
  return {
      //...state, // not needed as we are updated all state items below
      formIsValid: updatedFormIsValid,
      inputValidities: updatedValidities,
      inputValues: updatedValues
  };
  }
  return state;
};

// create a component
const CTACustomComponent = (props) => {
    const dispatch = useDispatch();

    //const location = props.location;
    const imageUrl = props.imageUrl;
    const [submitted, setSubmitted] = useState(false);
    const [formState, dispatchFormState] = useReducer(formReducer, {
      inputValues: {
        email: "",
        location: "",
        type: props.type
      },
      inputValidities: {
        email: true,
        location: true,
        type: true
      },
      formIsValid: true,
    });

    const inputChangeHandler = useCallback(
        (inputIdentifier, inputValue, inputValidities) => {
          dispatchFormState({
            type: FORM_INPUT_UPDATE,
            input: inputIdentifier,
            value: inputValue,
            isValid: true,
          });
        },
        [dispatchFormState]
      );
    
    const subscribeEventTrack = (category, action, label) => {
        ReactGA.event({
            category: category,
            action: action,
            label: label,
            value: 1
        })
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const subscriber = {
            email: formState.inputValues.email,
            location: props.location,
            type: formState.inputValues.type
        }
        const action = subscribe(subscriber);
        try {
            await dispatch(action);
            setSubmitted(true);
            subscribeEventTrack('subscription', props.type, props.location);
            if (props.type === 'download') {
                const pdfFile = '/assets/img/cta/pdfs/' + imageUrl + '.pdf';
                fetch(pdfFile).then(response => {
                    response.blob().then(blob => {
                        const fileURL = window.URL.createObjectURL(blob);
                        // Setting various property values
                        let alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = imageUrl + '.pdf';
                        alink.click();
                    })
                })
            }
        } catch (err) {
            console.log("ERROR:", err.message);
        }
    };

    return (
        <div className="cta__wrapper">
        <img src={`/assets/img/cta/${props.imageUrl}.png`} title={props.title} alt={props.title} width="150" />
        <div className="cta__wrapper--widthbuilder">
            <h4>{props.title}</h4>
            <p>{props.blurb}</p>
            {submitted ? 
            (<div className="subscribe-box__thanks"><h4>Thank you</h4><p>Look for our latest newsletters in your inbox!</p></div>
            ) : (
            
                <div className="cta__form--wrapper">
                    <form method="POST" onSubmit={handleSubmit} className="cta__form">
                        <div className="cta__form--input-wrapper">
                            <Input
                                variant="normal"
                                margin="none"
                                required
                                fullWidth
                                name="email"
                                label="Email Address"
                                type="email"
                                id="email"
                                autoComplete="email"
                                onInputChange={inputChangeHandler}
                            />
                        </div>
                        <input
                            type="submit"
                            value="Subscribe"
                            className="btn__sub form-btn"
                            data-reactid=".hbspt-forms-2.5.1.0"
                        />
                    </form>
                    <span className="italic_note">No spam ever. Unsubscribe at any time.</span>
                </div>
            )}
        </div>
        </div>
    )
};

//make this component available to the app
export default CTACustomComponent;