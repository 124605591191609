import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import * as toolsActions from '../actions/tools';

const SocialPostLinks = (props) => {
    const dispatch = useDispatch();
    const [links, getLinks] = useState(null);

    const fetchSocialLinks = useCallback(async () => {
        try {
          const data = await dispatch(toolsActions.loadSocialLinks());
          const linkdata = data ?  data.settings.socialsettings.socialSites : null;
          const filteredLinks = linkdata.filter(item => item.url !== '')
          getLinks(filteredLinks);
        } catch (err) {
          console.log("error", err);
        }
      }, [dispatch, props.user]);
    
      useEffect(() => {
        fetchSocialLinks();
      }, [fetchSocialLinks, props.user]);

  return (
    <div className="social">
      {links && links.length > 0 ? (
        <ul>
          {links.map((link, index) =>  (
            <li key={index}>
              <a href={`${link.url}`} target="_blank" rel="noopener noreferrer">
                <i className={`fab fa-${link.name.toLowerCase()}`}></i>
              </a>
            </li>)


          )}
        </ul>
      ) : (
        ""
      )}{" "}
    </div>
  );
};

export default SocialPostLinks;
