//import libraries
import React from "react";
import {Link} from "react-router-dom";

// create a component
const FeatureItem = (props) => {
  const data = props.postDetails;
  const postImageStyle = { 
    'background': `url(${data.postImage})`, 
    'backgroundPosition': 'center center',
    'backgroundSize': 'cover'
  }
  return (
    <li className="feature-box__item">
      <Link to={{pathname: `../post/${data.postTitleUrl}/`, data: {id: data.postId, url: data.postTitleUrl }}} className="feature-box__link" />
      <div className="feature-box__item-text">
        <div className="vertical-middle">
          <div className="vertical-middle__inner">
            <div className="feature-box__item-content">
              <span className="feature-box__item-content--label">0{props.number}</span>
              <h4>{data.postTitle}</h4>

              <div className="feature-box__item-content--promo">
                <span className="feature-box__item-category">
                  <span className="featured-box__item-category--inner">
                    {data.postTags}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="feature-box__image" style={postImageStyle}></div>
    </li>
  );
};

//make this component available to the app
export default FeatureItem;
