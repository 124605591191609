//import libraries
import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import CreatableSelect from "react-select/creatable";
import BuilderHeader from "../../containers/builder/BuilderHeader";
import BuilderContent from "../../containers/builder/BuilderContent";
import BuilderNavigation from "../../containers/builder/BuilderNavigation";
import EmailEditor from "react-email-editor";

import * as actions from "../../actions/tools";

const createOption = (label: string) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ""),
});

const defaultOptions = [];


// create a component
const EmailBuilder = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(defaultOptions);
  const [value, setValue] = useState();
  const emailEditorRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [designName, setDesignName] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const optionsX = await dispatch(actions.getDesignOptions());
        setOptions(optionsX);
        setValue(optionsX[1]);
      } catch (err) {
        console.log("error", err);
      }
    })();
  }, [setOptions, dispatch, props.postId]);

  const saveDesign = async () => {
    setIsSaving(true);
    await emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
        //setDesign(design);
        dispatch(actions.saveEmailDesign(design, designName, html));
      })

      await emailEditorRef.current.editor.saveDesign((design) => {});
    setTimeout(() => setIsSaving(false), 2000);
  };

  const onDesignLoad = (data) => {};

  const onLoad = async () => {
    const designData = await dispatch(actions.loadDefaultDesign());
    emailEditorRef.current.editor.addEventListener(
      "design:loaded",
      onDesignLoad
    );
    emailEditorRef.current.editor.loadDesign(designData.design);
   // setDesign(designData.design);
    setDesignName(designData.name);
    setValue({value: designData.templateId, label: designData.name});
  };

  const onReady = () => {
  };

  const handleSelectedChange = (e) => {
    (async () => {
      try {
        setValue(e);

        const designData = await dispatch(actions.loadDesign(e.value));
        emailEditorRef.current.editor.addEventListener(
          "design:loaded",
          onDesignLoad
        );

        emailEditorRef.current.editor.loadDesign(designData.design);
       // setDesign(designData.design);
        setDesignName(designData.name);
      } catch (err) {
        console.log("error", err);
      }
    })();
  };

  const handleCreate = (inputValue: string) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoading(false);
      setOptions((prev) => [...prev, newOption]);
      setDesignName(newOption.value);
    }, 1000);
  };

  return (
    <div className="admin__app">
      <div className="admin__container">
        <div className="admin__container--nav-build">
          <BuilderNavigation />
          <div className="admin__main-container">
            <BuilderHeader />
            <BuilderContent>
              <div className="admin__main-container--controlBox-wrapper">
                <button
                  className={
                    `btn__root btn__sub--white` +
                    (isSaving ? " btn__disabled" : "")
                  }
                  onClick={saveDesign}
                  disabled={isSaving}
                >
                  {" "}
                  {isSaving ? "Saving..." : "Save Design"}
                </button>

                <CreatableSelect
                  isClearable
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  onChange={(newValue) => handleSelectedChange(newValue)}
                  onCreateOption={handleCreate}
                  options={options}
                  value={value}
                />
              </div>

              <EmailEditor
                ref={emailEditorRef} 
                onLoad={onLoad}
                onReady={onReady}
              />
            </BuilderContent>
          </div>
        </div>
      </div>
    </div>
  );
};

//make this component available to the app
export default EmailBuilder;
