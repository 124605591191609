export default function wordCounter(text) {
    //const text = /* ... */;
    console.log('text', text)
    // create array of words
    const words = text.split(' ');

    let wordCount = 0;
    words.forEach((word) => {
      if (word.trim() !== '') {
        wordCount++;
      }
    });

    return wordCount;
}