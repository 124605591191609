//import libraries
import React from "react";

// create a component
const SocialListItem = (props) => {
  return (
    <div
      key={props.itemKey}
      id={props.itemKey}
      onClick={props.onClick}
      className="cursor"
    >
      <div className="flex--inline justify--sb u-padding-2">
        <div className="flex--inline flex--direction-col width-100">
          <div className="flex--inline alignment-items--center justify--sb">
            <div className="flex--inline alignment-items--center">
              <span style={{ width: "5rem" }}>
                <i
                  className={props.socialSite.icon}
                  style={{
                    color: `${props.socialSite.color}`,
                    background: `${props.socialSite.bgColor}`,
                    fontSize: "3.6rem",
                    padding: "2px",
                  }}
                ></i>
              </span>

              <div>
                <h4 className="color--white">{props.acct.name}</h4>
                <div className="font--size-small color--grey-light">
                  <span className="weight--bold color--secondary u-margin-right-1">
                    Profile:
                  </span>
                  {props.acct.profileName}
                </div>
                <div className="font--size-small color--grey-light">
                  <span className="weight--bold color--secondary u-margin-right-1">
                    Page:
                  </span>
                  {props.acct.pageName}
                </div>
              </div>
            </div>
            <div>
              {props.activeIndices.has(props.itemKey) ? (
                <span className="color--green font--size-xlarge">
                  <i className="fa-solid fa-check"></i>
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//make this component available to the app
export default SocialListItem;
