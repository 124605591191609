//import libraries
import React from "react";
import {Link} from 'react-router-dom';
import Moment from 'react-moment';

// create a component
const PostFeature = (props) => {
  const postId = props.postDetails.postId;
  const postTitle = props.postDetails.postTitle;
  const postTitleUrl = props.postDetails.postTitleUrl;
  const postBlurb = props.postDetails.postBlurb;
  const postImage = props.postDetails.postImage;
  const postAuthor = props.postDetails.postAuthor;
  const postTags = props.postDetails.postTags;
  const updateDate = props.postDetails.updatedAt;
  

  return (
    <div
      id={postId}
      className="article-item post clearfix post-2816 type-post status-publish format-standard has-post-thumbnail hentry category-content-marketing tag-community tag-seo tag-strategies"
    >
      <div className="post-featured-item">
        <div className="post-featured-item-inner">
          <Link
              to={{pathname: `/post/${postTitleUrl}/`, data: {id: postId, url: postTitleUrl }}}
          >
            <img
              src={postImage}
              className="post-featured-item--img"
              alt={postTitle}
              srcSet=""
            />
          </Link>
          <p>
          <span className="cat">
                <a href={`../categories/${postTags}`}>
                  {postTags}
                </a>
              </span></p>
        </div>
      </div>

      <div className="post-entry-wrapper">
        <div className="post-header">
          <h3>
            <Link
              to={{pathname: `/post/${postTitleUrl}/`, data: {id: postId, url: postTitleUrl }}}
          >
              {postTitle}
            </Link>
          </h3>

          <div className="date-author">
            <p>
              <span className="author">{postAuthor}</span>
              <span className="date"><Moment format="MMM Do YYYY" date={updateDate} /></span>
            </p>
            </div>
            <div>
            
          </div>
        </div>

        <div className="post-entry">
          <div className="post-entry-text">
            <p>
              {postBlurb}
            </p>
          </div>
        </div>

        <div className="post-entry-bottom clearfix">
          <Link
            className="custom-more-link"
            to={{pathname: `/post/${postTitleUrl}/`, data: {id: postId, url: postTitleUrl }}}
          >
            Read More
          </Link>
        </div>
      </div>
    </div>
  );
};

//make this component available to the app
export default PostFeature;
