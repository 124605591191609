//import libraries
import React from 'react';
import { CellPlugin } from "@react-page/editor";
import CustomAdComponent from "./customComponents/CustomAdComponent";

type Data = {
    source: string,
    imagelink: string,
    imghlink: string,
    idNumber: integer
};

const CustomAd: CellPlugin<Data> = {
  Renderer: ({ data }) => (
    <CustomAdComponent
      source={data.source}
      imageUrl={data.imageUrl}
      idNumber={data.idNumber}
      imagelink={data.imagelink}
    />
  ),
  id: "CustomAdPlugin",
  title: "Ad Plugin",
  description: "Add an ad to the page",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      properties: {
        source: {
            type: "string",
            default: "Welcome to 61ThreeTwenty.com", 
          },
          idNumber: {
            type: "string",
            default: "1"
        },
        imagelink: {
          type: "string",
          default: "Welcome to 61ThreeTwenty.com", 
        },
        imghlink: {
          type: "string",
          default:'/assets/img/default.png'
        }
      },
      required: ["imagelink", "imghlink"],
    },
  },
};

export default CustomAd;