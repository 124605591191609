//import libraries
import React from "react";
import { Link } from "react-router-dom";

// create a component
const PostListItem = (props) => {
  const data = props.postDetails;

  return (
    <li>
      <div className="clearfix">
        <div className="thumb size_50_50">
          <Link
            to={{
              pathname: `/post/${data.postTitleUrl}/`,
              data: { id: data.postId, url: data.postTitleUrl },
            }}
          >
            <img
              width="150"
              height="150"
              src={`${data.postImage}?resize=150%2C150&amp;ssl=1`}
              className="attachment-thumbnail size-thumbnail wp-post-image"
              alt={`${data.postTitle}`}
            />
          </Link>
        </div>
        <div className="recent_post_text">
          <h4>
            <Link
              to={{
                pathname: `/post/${data.postTitleUrl}/`,
                data: { id: data.postId, url: data.postTitleUrl },
              }}
              className="recent_post_widget_header"
            >
              {data.postTitle}
            </Link>
          </h4>
          <span className="post-category">{data.postTags}</span>
        </div>
      </div>
    </li>
  );
};

//make this component available to the app
export default PostListItem;
