//import libraries
import React from "react";

// create a component
const Button = (props) => {
  const disabled = props.disabled ? true : false;
  return (
    <button
      id={props.id ? props.id : 99}
      onClick={props.onClick}
      className={disabled ? "btn__disabled" : props.className}
      type={props.type}
      disabled={disabled}
    >
      {props.text}
    </button>
  );
};

//make this component available to the app
export default Button;
