//import libraries
import React, { useState, useReducer, useEffect } from "react";
import FieldSet from "./FieldSet";
import FormInputLabel from "./FormInputLabel";

const INPUT_CHANGE = 'INPUT_CHANGE';
const INPUT_BLUR = 'INPUT_BLUR';

const inputReducer = (state, action) => {
  switch (action.type) {
      case INPUT_CHANGE:
          return {
              ...state,
              value: action.value,
              isValid: true //action.isValid
          };
      case INPUT_BLUR:
          return {
             ...state,
              touched: true
          };
      default:
          return state;
  }
}

// create a component
const Input = (props) => {
  const iVal = props.initialValue ? props.initialValue : '';
  const fullWidth = props.fullWidth ? "--full-width" : null;
  const variant = props.variant ? `${props.variant}` : null;
  const required = props.required ? true : false;
  const [filled, setFilled] = useState(false);
  const [focus, setFocus] = useState(false);
  const [fieldLabel, setFieldLabel] = useState(props.label ? props.label : '');
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue ? props.initialValue : '',
    isValid: true, //props.initiallyValid,
    touched: false
  });
  const { onInputChange, id } = props;

  const handleOnFocus = () => {
    setFocus(true);
    setFilled(false);
  };

  const handleOnBlur = (e) => {
    setFocus(false);
    
    if (e.target.value !== '') {
      setFilled(true);

    } else {
      setFilled(false);
    }
    dispatch({type: INPUT_BLUR})
  }

  useEffect(() => {

    if(inputState.touched) {
      if (onInputChange) {
        if (inputState.value === '') {
          setFieldLabel(props.label)
        } else {
          setFieldLabel('')
        }
        onInputChange(id, inputState.value, inputState.isValid);
      }
    }
  }, [inputState, onInputChange, id, props.label]);

  const textChangeHandler = (text) => {
    if (text === '') {
      setFieldLabel(props.label)
    } else {
      setFieldLabel('')
    }
      dispatch({type: INPUT_CHANGE, value: text.currentTarget.value, isValid: true})
  }

  return (
    <div
      className={`bhFormControl__root ${
        fullWidth ? `bhFormControl${fullWidth}` : null
      } 
    bhFormControl--margin-${props.margin}`}
    >
      <FormInputLabel
        label={fieldLabel}
        fullWidth={fullWidth}
        variant={variant}
        size={props.margin}
        focus={focus}
        filled={filled}
        initialValue={iVal}
      />
      <div
        className={`bhinput__root ${fullWidth ? `bhinput${fullWidth}` : null} ${
          variant ? `bhinput-${variant}` : null
        } ${focus ? `bhinput--focused` : ""}`}
      >
        <input
          required={required}
          className={`bhinput--input bhinput-${variant}--input`}
          name={props.name}
          label={props.label}
          type={props.type}
          id={props.id}
          defaultValue={iVal}
          autoComplete={props.autoComplete}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onChange={textChangeHandler}
        />
       <FieldSet focus={focus} label={props.label} variant={props.variant} />
      </div>
    </div>
  );
};

//make this component available to the app
export default Input;
