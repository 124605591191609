//import libraries
import React, { useReducer, useState, useCallback } from "react";
import ReactGA from 'react-ga4';
import { useDispatch } from "react-redux";
import Input from './UI/Input';
import { subscribe } from "../actions/session";
import { sendSubscribeEmail } from "../actions/mail";

// create a component

const FORM_INPUT_UPDATE = "FORM_INPUT_UPDATE";

const formReducer = (state, action) => {
  if (action.type === FORM_INPUT_UPDATE) {
    const updatedValues = {
      ...state.inputValues,
      [action.input]: action.value
  }
  const updatedValidities = {
      ...state.inputValidities,
      [action.input]: action.isValid
  }
  let updatedFormIsValid = true;
  for (const key in updatedValidities) {
      updatedFormIsValid = updatedFormIsValid && updatedValidities[key];
  }
  return {
      //...state, // not needed as we are updated all state items below
      formIsValid: updatedFormIsValid,
      inputValidities: updatedValidities,
      inputValues: updatedValues
  };
  }
  return state;
};


const SubscribeBox = (props) => {
  const dispatch = useDispatch();

  //const location = props.location;
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      email: "",
      location: "",
      type: props.type
    },
    inputValidities: {
      email: true,
      location: true,
      type: true
    },
    formIsValid: true,
  });

  const inputChangeHandler = useCallback(
    (inputIdentifier, inputValue, inputValidities) => {
      dispatchFormState({
        type: FORM_INPUT_UPDATE,
        input: inputIdentifier,
        value: inputValue,
        isValid: true,
      });
    },
    [dispatchFormState]
  );

  const subscribeEventTrack = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
      value: 1
    })
  }

  const validateEmail = function(email) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(email);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const subscriber = {
      email: formState.inputValues.email,
      location: props.location,
      type: formState.inputValues.type
    }
    const action = subscribe(subscriber);
    
    try {
      if(validateEmail(subscriber.email)) {
        await dispatch(action);
        await dispatch(sendSubscribeEmail(subscriber.email));
        setSubmitted(true);
        subscribeEventTrack('subscription',props.type, props.location)
      } else {
        setError(true)
      }
    } catch (err) {
      console.log("ERROR:", err.message);
    }
  };
  
  return (
    
    <div className="subscribe-box">
    <div className="subscribe-box__title"><span>Don't miss out!</span></div>
    <div className="subscribe-box__intro">Get the latest updates, tools, free stuff, and reviews right to your inbox!
    </div>
      {error ?? <div className="error">Email input is not valid</div>}
      {submitted ? 
      (<div className="subscribe-box__thanks"><h4>Thank you</h4><p>Look for our latest newsletters in your inbox!</p></div>
      ) : (
        <>
      <form method="POST" onSubmit={handleSubmit} className="subscribe-box--form">
      <div className="subscribe-box__input-wrapper">
        <Input
            variant="normal"
            margin="none"
            required
            fullWidth
            name="email"
            label="Email Address"
            type="email"
            id="email"
            autoComplete="email"
            onInputChange={inputChangeHandler}
          />
        </div>
        <input
          type="submit"
          value="Subscribe"
          className="btn__sub form-btn"
          data-reactid=".hbspt-forms-2.5.1.0"
        />
      </form>
      <span className="italic_note">No spam ever. Unsubscribe at any time.</span>
      </>)}
    </div>
  );
};

//make this component available to the app
export default SubscribeBox;
