//--- client/scr/actions/session.js ---
import * as apiUtil from '../util/session';
import { receiveErrors, receiveSignupErrors } from './error';

export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';
export const LOGOUT_CURRENT_USER = 'LOGOUT_CURRENT_USER';
export const SUBSCRIBE_CURRENT_USER = 'SUBSCRIBE_CURRENT_USER';
export const CONTACT_EMAIL_SENT = 'CONTACT_EMAIL_SENT';
export const RECEIVE_USER_SETTINGS = 'RECEIVE_USER_SETTINGS';


const receiveCurrentUser = (user) => ({
    type: RECEIVE_CURRENT_USER,
    user
});

const receiveUserSettings = (settings) => ({
    type: RECEIVE_USER_SETTINGS,
    settings
});


const logoutCurrentUser = () => ({
    type: LOGOUT_CURRENT_USER
});

const subscribeEmail = (email, location, subscribetype) => ({
    type: SUBSCRIBE_CURRENT_USER,
    email,
    location,
    subscribetype
});

const contactInformation = (info) => ({
    type: CONTACT_EMAIL_SENT,
    name: info.name,
    email: info.email,
    info: info.info
});

export const login = (user) => async dispatch => {
    const response = await apiUtil.login(user);
    const data = await response.json();
    if (response.ok) {
        await dispatch(receiveCurrentUser(data));
        return data
    }

    return dispatch(receiveErrors(data));
};

export const signup = (user) => async dispatch => { 
    const response = await apiUtil.signup(user);
    const data = await response.json();
    if (response.ok) {
        const userEmail = data.email;
        return userEmail;
        //return dispatch(receiveCurrentUser(data));
    }

    return dispatch(receiveSignupErrors(data));
};

export const emailValidate = (pin) => async dispatch => { 
    const response = await apiUtil.emailValidate(pin);
    const data = await response.json();
    if (response.ok) {
        return dispatch(receiveCurrentUser(data));
    }

    return dispatch(receiveSignupErrors(data));
};

export const checkUserSocialSettings = (uid) => async dispatch => {
    const response = await fetch(`/api/users/checkUserSocialSettings/${uid}`);
    const data = await response.json();
    if (response.ok) {
        dispatch(receiveUserSettings(data.settings));
        return data;
    }

    return dispatch(receiveErrors(data));
}

export const saveSocialPreferences = (uid, prefs) => async dispatch => {
    const fetchdata = {userId: uid, socialSites: prefs}
    const response = await fetch(`/api/tools/saveSocialPreferences`, {
        method: 'POST',
        body: JSON.stringify(fetchdata),
        headers: {
            "Content-Type": "application/json"
        }
    })
    const data = await response.json();
   
    if(response.ok) {
        return dispatch(receiveUserSettings(data.settings));
        //return data;
    } 
    
    return dispatch(receiveErrors(data));
    
}


export const logout = () => async dispatch => {
    const response = await apiUtil.logout();
    const data = await response.json();

    if (response.ok) {
        return dispatch(logoutCurrentUser());
    }

    const message = dispatch(receiveErrors(data));
    console.log('err message: ', message)
    return message;
}

export const subscribe = (subscriberInfo) => async dispatch => {
    const response = await apiUtil.subscribe(subscriberInfo);
    if (response.ok) {
       return dispatch(subscribeEmail(subscriberInfo.email, subscriberInfo.location, subscriberInfo.type));    
    }     
}

export const contact = (contactInfo) => async dispatch => {
    const response = await apiUtil.contactForm(contactInfo);
    if (response.ok) {
        return dispatch(contactInformation(contactInfo));
    }
}

export const removeSocialToken = (uid, id, token) => async dispatch => {
    const data = {
        uid,
        id,
        token
    }

    const response = await fetch(`/api/users/removeSocialToken/`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    })
  
    const responseData = await response.json();
    if (response.ok) {
        
        dispatch(receiveUserSettings(responseData.settings));
        return responseData;
    }

    return dispatch(receiveErrors(responseData));
}
export const saveSocialToken = (id, result) => async dispatch => {
    const data = {
        id: id,
        uid: result.id,
        site: result.site,
        token: result.token,
        page: result.pageName,
        category: result.pageCategory,
        name: result.profileName
    }
    const response = await fetch(`/api/users/saveSocialToken/`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    })
    const responseData = await response.json();
    if (response.ok) {
        dispatch(receiveUserSettings(responseData.settings));
        return responseData;
    }

    return dispatch(receiveErrors(responseData));
}