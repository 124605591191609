import React from 'react';
import { CellPlugin } from "@react-page/editor";
import CardChoiceCustomComponent from './customComponents/CardChoiceCustomComponent';

type Data = {
    logoUrl: string,
    siteUrl: string,
    score: string,
    companyName: string,
    blurb: string,
    itemOne: string,
    itemTwo: string,
    itemThree: string,
    itemFour: string,
    textOne: string, 
    textTwo: string, 
    textThree: string, 
    costOne: string, 
    costTwo: string, 
    costThree: string, 
  };
  
  const CardChoice: CellPlugin<Data> = {
    Renderer: ({ data }) => (
      <CardChoiceCustomComponent
        logoUrl={data.logoUrl}
        siteUrl={data.siteUrl}
        score={data.score}
        companyName={data.companyName}
        blurb={data.blurb}
        itemOne={data.itemOne}
        itemTwo={data.itemTwo}
        itemThree={data.itemThree}
        itemFour={data.itemFour}
        textOne={data.textOne}
        textTwo={data.textTwo}
        textThree={data.textThree}
        costOne={data.costOne}
        costTwo={data.costTwo}
        costThree={data.costThree}
      />
    ),
    id: "cardchoiceplugin",
    title: "Card Coice",
    description: "Card to display for best of list",
    version: 1,
    controls: [
    {
    title: 'Overview',
    controls: {
      type: "autoform",
      schema: {
        properties: {
            logoUrl: {
                type: "string",
                default:"https://"
            },
            siteUrl: {
              type: "string",
              default:"https://"
          },
          score: {
            type: "string",
            default: "9.2",
          },
          companyName: {
            type: "string",
            default:'A'
          },
          blurb: {
            type: "string",
            default: "",
          },
          itemOne: {
            type: "string"
          },
          itemTwo: {
            type: "string"
          },
          itemThree: {
            type: "string"
          },
          itemFour: {
            type: "string"
          },
        },
        required: ['logoUrl', "score", "companyName"],
      },
    },
  },
    {
      title: 'Pricing',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            textOne: {
              type: "string"
            },
            textTwo: {
              type: "string"
            },
            textThree: {
              type: "string"
            },
            costOne: {
              type: "string"
            },
            costTwo: {
              type: "string"
            },
            costThree: {
              type: "string"
            },
          }
        }
      }
    }
  ]
  };
  
export default CardChoice;