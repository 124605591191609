//import libraries
import React, { useState, useEffect, useCallback, useReducer } from "react";
import { useSelector } from "react-redux";
import Button from "./Button";
import Input from "./Input";

const FORM_INPUT_UPDATE = "FORM_INPUT_UPDATE";
const FORM_BUTTON_UPDATE = "FORM_BUTTON_UPDATE";

const formReducer = (state, action) => {
  if (action.type === FORM_INPUT_UPDATE) {
    const updatedList = state.socialSites.map((item) => {
      const num = action.input;
      const id = parseInt(num.substring(0, num.indexOf("-")));
      const input = num.split('-').pop();
      if (item.id === id) {
        if (input === 'url') {
          return { ...item, url: action.value }; //gets everything that was already in item, and updates "done"
        } else {
          return { ...item, authentication: action.value }; //gets everything that was already in item, and updates "done"
        }
      }
      return item; // else return unmodified item
    });
  return {
    socialSites: updatedList
  };
  }

  if (action.type === FORM_BUTTON_UPDATE) {
    const updatedList = state.socialSites.map((item, index) => {
      if (item.id ===  parseInt(action.input.target.id)) {
        return { ...item, visible: !item.visible }; //gets everything that was already in item, and updates "done"
      }
      return item; // else return unmodified item
    });
  return {
    socialSites: updatedList
  };
  }
  return state;
};



// create a component
const SocialButtonGroup = (props) => {
  const sites = props ? props.socialSites : [];
  //const sessionState = useSelector((state) => state.session); 
  const userSettings = useSelector((state) => state.session.settings); 
  //const userId = useSelector((state) => state.session.userId);   
  const [currentSocialSettings, setCurrentSocialSettings] = useState(userSettings ? userSettings.socialsettings : []);
  const [formState, dispatchFormState] = useReducer(formReducer, userSettings.socialsettings);


  const handleBtn = useCallback(
    (inputIdentifier, inputValue, inputValidities) => {
    
    dispatchFormState({
        type: FORM_BUTTON_UPDATE,
        input: inputIdentifier,
        value: inputValue,
    });
    },
    [dispatchFormState]
    
  );

  const inputChangeHandler = useCallback(
    (inputIdentifier, inputValue, inputValidities) => {
    dispatchFormState({
        type: FORM_INPUT_UPDATE,
        input: inputIdentifier,
        value: inputValue,
    });
    },
    [dispatchFormState]
    
  );

  useEffect(()=>{
    setCurrentSocialSettings(formState);
    props.onChangeHandler(formState)
  },[formState, props])

  return (
    <div>
      {sites && sites.socialSites.map((btn, index) => {
        return (
        <Button
          id={btn.id}
          key={btn.id}
          type="input"
          className={`btn u-margin-top-small btn__social ${
            currentSocialSettings ? currentSocialSettings.socialSites[index] ? currentSocialSettings.socialSites[index].visible ? "btn__social-active" : "" : "" : ""
          }`}
          text={currentSocialSettings ? currentSocialSettings.socialSites[index] ? currentSocialSettings.socialSites[index].visible ? "\u2713  " + btn.name : btn.name : `default${btn.id}` : `default${btn.id}` }
          value={btn.id}
          onClick={handleBtn}
        />
        )
      })}
      {
        currentSocialSettings && currentSocialSettings.socialSites.map(
          (item, idx) =>
          item.visible && (
              <div className="social--admin-inputs" key={`${item.id}-divkey`}>
              <span lassName="w--8"><i className={sites.socialSites[idx].icon} style={{"color": `${sites.socialSites[idx].color}`, "background": `${sites.socialSites[idx].bgColor}`, "fontSize": "3.6rem", "padding": "2px" }}></i></span>
              <div className="w--100">
              <Input
                variant="outlined"
                margin="normal"
                required
                fullWidth
                key={`${item.id}`}
                id={`${item.id}-url`}
                label={`${item.name} URL`}
                name={item.name}
                autoComplete="name"
                initialValue={`${item.url}`}
                autoFocus
                onInputChange={inputChangeHandler}
              />
              </div>
              </div>
            )
        )}
    </div>
  );
};

//make this component available to the app
export default SocialButtonGroup;
