//import libraries
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import * as toolActions from '../actions/tools';

// create a component
const RelatedPost = (props) => {
    const dispatch = useDispatch();
    const ids = props.ids ? props.ids : "";
    const [posts,getPostInfo] = useState([]);
      
    // create useEffect here for getting the data
    useEffect(() => {
      (async () => {
        try {
        if(ids !== ""){
          const insidePost = await dispatch(toolActions.loadRelatedPosts(ids));
          getPostInfo(insidePost);
        }
        } catch (err) { 
          console.log('error', err);
        } 
      })();
    }, [dispatch, ids]);

    return (
        <>
      <h2>Related Links</h2>             
      <div className="related-articles-box">
        <div className="related-articles-box__item">
        {posts.map((post, index) => (
          <div key={post.postId} className="related-articles-box__item--box">
          <div className="related-articles-box--image display-inherit">
            <img src={`${post.postImage}`} alt="Cool" />
            <h3><Link  to={{pathname: `/post/${post.postTitleUrl}/`, data: {url: post.postTitleUrl }}}>{post.postTitle}</Link></h3>
          </div>
          <div>
            <p className="related-articles-box--info">{post.postBlurb}</p>
            <Link  to={{pathname: `/post/${post.postTitleUrl}/`, data: {url: post.postTitleUrl }}}>Read More</Link>
          </div>
      </div>
      ))}
      </div>
      </div>
      </>
    );
};

//make this component available to the app
export default RelatedPost;
