import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import wordCounter from '../util/wordCounter';

import Editor from "@react-page/editor";
import slate from "@react-page/plugins-slate";
import video from "@react-page/plugins-video";
import spacer from "@react-page/plugins-spacer";
import AmazonAd from './UI/builder/Post/AmazonAd';
import AffiliateBox from './UI/builder/Post/AffiliateBox';
import BlockQuote from "./UI/builder/Post/BlockQuote";
import CTABuilder from "./UI/builder/Post/CTABuilder"
import PostContainerLayout from "./UI/PostContainerLayout";
import codeSnippet from "../components/UI/builder/Post/CodeSnippet";
import CustomDivider from "../components/UI/builder/Post/CustomDivider";
import CardChoice from "../components/UI/builder/Post/CardChoice";
import CustomAd from "../components/UI/builder/Post/CustomAd";
import CustomCaption from "../components/UI/builder/Post/CustomCaption";
import CustomGif from "../components/UI/builder/Post/CustomGif";
import CustomImage from "../components/UI/builder/Post/CustomImage";
import CustomVideo from "../components/UI/builder/Post/CustomVideo";
import PostEntryInfo from "../components/UI/builder/Post/PostEntryInfo";
import TopChoiceBlock from "../components/UI/builder/Post/TopChoice";

import '@react-page/editor/lib/index.css';
import '@react-page/plugins-slate/lib/index.css';
import '@react-page/plugins-image/lib/index.css';

import * as actions from "../actions/tools";

const cellPlugins = [
  slate(),
  PostEntryInfo,
  BlockQuote,
  CustomImage,
  CustomDivider,
  spacer,
  CustomCaption,
  codeSnippet,
  CustomAd,
  AffiliateBox,
  AmazonAd,
  CardChoice,
  CTABuilder,
  CustomGif,
  CustomVideo,
  TopChoiceBlock,
  video
];

const PostBuilder = (props) => {
  const [value, setValue] = useState(null);
  const [postId, setPostId] = useState(null);
  const { data } = useLocation();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.session.userId);
  const pubVal = data ? (data.publishState ? 'Switch To Draft' : 'Publish') : 'Publish';
  const pubStateVal = data ? (data.publishState ? data.publishState : false) : false;
  const [publishValue, setPublishValue] = useState(pubVal);
  const [pubState, setPubState] = useState(pubStateVal)
  const [isSaving, setIsSaving] = useState(false)
  const [wordCount, setWordCount] = useState();
  
  const testSentence = "This is a test sentence for word count.";

  useEffect(() => {
    const id = data ? data.id : null;
    const story = data ? data.story : null;
    setPostId(id);
    setValue(story);

   // let val = story ? story.dataset.['slate-string'] : '';
    const count = wordCounter(testSentence);
    setWordCount(count);
  }, [data, publishValue]);

  const onSaveHandler =  async () => {
    setIsSaving(true);
    await dispatch(actions.savePost(value, userId));
    setTimeout(() => setIsSaving(false), 2000);
  };

  const onPublishHandler = () => {
    const convertPublish = pubState ? 'Publish' : 'Switch To Draft';
    setPublishValue(convertPublish);
    dispatch(actions.publishUpdate(postId, !pubState))
    setPubState(!pubState)
  };

  return (
    <div>
      <div className="admin-content--container--header">
        <button
          className={`btn__root btn__sub--white` + (isSaving ? ' btn__disabled': '')}
          onClick={onSaveHandler}
          disabled={isSaving}
        >
          {isSaving ? 'Saving...' : 'Save Post'}
        </button>
        {/* <button className="btn__root btn__sub--white" onClick={onPreviewHandler} value="Submit">
          Preview
        </button> */}
        <button
          className="btn__root btn__sub"
          onClick={onPublishHandler}
        >
          {publishValue}
        </button>
        <div>
          <span>Current Word Count: {wordCount}</span>
        </div>
      </div>
      <PostContainerLayout>
        <Editor
          className="PCL-editor"
          cellPlugins={cellPlugins}
          value={value}
          onChange={setValue}
        />
      </PostContainerLayout>
    </div>
  );
};

export default PostBuilder;
