//import libraries
import React from "react";
import { NavLink } from "react-router-dom";

// create a component
const BuilderNavigation = (props) => {

  const pageList = [
    {
      page: "dashboard",
      name: "Dashboard",
      icon: "fa-solid fa-gauge-simple-high",
      type: "page"
    },
    {
      page: "pages",
      name: "Pages",
      icon: "",
      type: "title"
    },
    {
      page: "allpost",
      name: "Posts",
      icon: "fa-solid fa-clipboard-list",
      type: "page"
    },
    {
      page: "create",
      name: "Create Post",
      icon: "fa-solid fa-layer-group",
      type: "page"
    },
    {
      page: "email",
      name: "Create Email Campaign",
      icon: "fa-solid fa-layer-group",
      type: "page"
    },
    {
      page: "data",
      name: "Data",
      icon: "",
      type: "title"
    },
    {
      page: "subscribers",
      name: "Subscribers",
      icon: "fa-solid fa-address-book",
      type: "page"
    },
    {
      page: "categories",
      name: "Categories",
      icon: "fa-solid fa-diagram-project",
      type: "page"
    },
    {
      page: "messages",
      name: "Messages",
      icon: "fa-solid fa-comments",
      type: "page"
    },
    {
      page: "socialpostbuilder",
      name: "Social Post",
      icon: "fa-solid fa-share",
      type: "page"
    },
    {
      page: "calendar",
      name: "Calendar",
      icon: "fa-solid fa-calendar",
      type: "page"
    },
    {
      page: "settings",
      name: "Settings",
      icon: "",
      type: "title"
    },
    {
      page: "settings",
      name: "Settings",
      icon: "fa-solid fa-gears",
      type: "page"
    },
    {
      page: "socialsettings",
      name: "Social Settings",
      icon: "fa-solid fa-share-from-square",
      type: "page"
    },
  ];

  return (
    <div className="admin-navigation">
      <div className="admin-navigation__sidebar">
        <div className="admin-navigation__sidebar--content">
          <div className="header-wrapper--logo-dash">
            <NavLink aria-current="page" to="/">
              61<span>:</span>ThreeTwenty
            </NavLink>
          </div>
          <nav className="admin-navigation--nav-container">
            <ul className="admin-list__menu">
              {pageList.map((page, index) => {
                return page.type === 'title' ? (
                  <span key={index} className="admin-list__menu--title">{page.name}</span>
                ) : (
                  <li key={index} className="admin-list__menu--item" role="button">
                  <NavLink
                    to={`/builder/${page.page}`}
                    className="admin-list__menu--link"
                    title={page.name}
                  >
                    <div className="admin-list__menu--link--container">
                      <div className="admin-list__menu--icon">
                        <i className={`${page.icon}`}></i>
                      </div>
                      <span className="admin-list__menu--label">
                        {page.name}
                      </span>
                    </div>
                  </NavLink>
                </li>
                )
              }  
              )}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

//make this component available to the app
export default BuilderNavigation;