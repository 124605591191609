//import libraries
import React from "react";

// create a component
const CardChoiceCustomComponent = (props) => {
  return (
    <div className="rankingCard">
      <div className="rankingCard-wrapper">
        <div className="rankingCard-logo">
          <a href={`${props.siteUrl}`}>
            <img src={`${props.logoUrl}`} alt={`${props.companyName}`} />
          </a>
        </div>
        <div className="rankingCard-siteInfo">
          <h3>{props.companyName}</h3>
          <p>{props.blurb}</p>
        </div>
        <div className="rankingCard-listView">
          {props.itemOne && (
            <ul>
              <li>{props.itemOne}</li>
              {props.itemTwo && <li>{props.itemTwo}</li>}
              {props.itemThree && <li>{props.itemThree}</li>}
              {props.itemFour && <li>{props.itemFour}</li>}
            </ul>
          )}
        </div>
        <div className="rankingCard-customScore">
          <p>
            SCORE: <span>{props.score} </span>/10
          </p>
          <button className="btn__sub btn__sub--large">
            Visit {props.companyName}
          </button>
        </div>
      </div>
      <div className="rankingCard-wrapper">
        
          {props.textOne && (
            <div className="rankingCard-costBox">
            <h4>Today's Deals</h4>
            <ul>
              <li>
                {props.textOne} <span>${props.costOne}</span>/mth
              </li>
              {props.textTwo && (
                <li>
                  {props.textTwo} <span>${props.costTwo}</span>/mth
                </li>
              )}
              {props.textThree && (
                <li>
                  {props.textThree} <span>${props.costThree}</span>/mth
                </li>
              )}
            </ul>
            </div>
          )}
        
      </div>
    </div>
  );
};

//make this component available to the app
export default CardChoiceCustomComponent;
