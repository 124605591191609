//import libraries
import React from 'react';

// create a component
const SettingsCard = (props) => {
 return (
    <div className="card--settings u-padding-2 u-margin-bottom-medium">{props.children}</div>
 )
};



//make this component available to the app
export default SettingsCard;
