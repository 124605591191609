//import libraries
import React from 'react';


// create a component
const CustomGifComponent = (props) => {
    const imgText = props.imgText ? props.imgText : "";
    return (
        <div>
        <div className="w--100 h--30">
            <img src={props.sourceUrl} width="100%" height="100%" frameborder="0" className="giphy-embed" alt={imgText} allowFullScreen></img>
        </div>
        <p><a href={props.giphyUrl}>via GIPHY</a></p>
        </div>
    )
};

//make this component available to the app
export default CustomGifComponent;
