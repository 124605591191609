//import libraries
import React from "react";

// create a component
const CustomAdComponent = (props) => {
  return (
    <div className="u-margin-top-medium u-margin-bottom-medium">
      <a
        href={props.source}
        target="_top"
        id={props.idNumber}
        className="no-border"
      >
        <img
          src={props.imagelink}
          border="0"
          alt=""
          className="w-100"
        />
      </a>
      <img
        height="0"
        width="0"
        src={props.imghlink}
        alt="hidden"
        className="hai"
        border="0"
      />
    </div>
  );
};

//make this component available to the app
export default CustomAdComponent;
