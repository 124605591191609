import React from "react";

import ReactGA from "react-ga4";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import { AuthRoute, ProtectedRoute } from '../util/route';
import ScrollToTop from "../util/ScrollToTop";

// Pages
import About from "../Pages/About";
import Categories from "../Pages/Categories";
import Confirmation from "../Pages/Confirmation";
import Contact from "../Pages/ContactUs";
import Login from "../Pages/Login";
import Main from "../Pages/Main";
import NotFoundPage from "../components/NotFoundPage";
import Post from "../Pages/Post";
import PrivacyPolicy from "../Pages/PrivacyPolicy"
import SearchResults from "../Pages/SearchResults";
import SignUp from "../Pages/SignUp";
import TermsOfUse from "../Pages/Terms";
import WelcomeConfirmation from "../Pages/WelcomeConfirmation";
import Unsubscribe from "../Pages/Unsubscribe";


// Admin Pages
import AllPostRev from "../Pages/builder/AllPostRev";
import Calendar from '../Pages/builder/Calendar';
import CategoriesAdmin from "../Pages/builder/Categories";
import CreatePost from "../Pages/builder/CreatePost";
import Dashboard from "../Pages/builder/Dashboard";
import Email from "../Pages/builder/Email";
import Messages from "../Pages/builder/Messages";
import PagesList from "../Pages/builder/PagesList";
import Profile from "../Pages/builder/Profile";
import SiteSettings from "../Pages/builder/SiteSettings";
import SocialPost from "../Pages/builder/SocialPost";
import SocialSettings from "../Pages/builder/SocialSettings";
import Subscribers from "../Pages/builder/Subscribers";
import UsersList from "../Pages/builder/UsersList";
// Containers
import Footer from "../containers/Footer";
import Header from "../containers/Header";

const MainRouter = withRouter(({ location }) => {
  
  ReactGA.initialize("G-69GQNVLC45");
  ReactGA.send("pageview");
  return (
    <div className="wrapper">
      {location.pathname !== "/login" && location.pathname !== "/signup" && !location.pathname.includes("/builder/")  && (
        <Header />
      )} 
       <Switch>
        <Route path="/contact" component={Contact} />
        <Route path="/about" component={About} />
        <Route path="/categories/:id" component={Categories}/>
        <Route path="/post/:slug" component={Post} />
        <Route path="/search-results/" component={SearchResults} />
        <Route path="/confirmation/:userEmail" component={Confirmation} />
        <Route path="/" component={Main} exact />
        <Route path="/privacy" component={PrivacyPolicy} />
        <Route path="/termsofuse" component={TermsOfUse} />
        <Route path="/confirm/:confirmationCode" component={WelcomeConfirmation} />
        <Route path="/unsubscribe/:code" component={Unsubscribe} />

        <AuthRoute path="/login" component={Login} />
        <AuthRoute path="/signup" component={SignUp} />

        <ProtectedRoute path="/builder/dashboard" component={Dashboard} />
        <ProtectedRoute path="/builder/allpost" component={AllPostRev} exact />
        <ProtectedRoute path="/builder/create" component={CreatePost} />
        <ProtectedRoute path="/builder/profile" component={Profile} />
        <ProtectedRoute path="/builder/users" component={UsersList} />
        <ProtectedRoute path="/builder/pages" component={PagesList} />
        <ProtectedRoute path="/builder/calendar" component={Calendar} />
        <ProtectedRoute path="/builder/categories" component={CategoriesAdmin} />
        <ProtectedRoute path="/builder/messages" component={Messages} />
        <ProtectedRoute path="/builder/settings" component={SiteSettings} />
        <ProtectedRoute path="/builder/socialsettings" component={SocialSettings} />
        <ProtectedRoute path="/builder/socialpostbuilder" component={SocialPost} />
        <ProtectedRoute path="/builder/subscribers" component={Subscribers} />
        <ProtectedRoute path="/builder/email" component={Email} />
        <Route component={NotFoundPage} />
      </Switch>
      {location.pathname !== "/login" && !location.pathname.includes("/builder/") && location.pathname !== "/signup" && (
        <Footer />
      )}
    </div>
  );
});

const AppRouter = ({ location }) => (
  <BrowserRouter>
    <ScrollToTop />
    <MainRouter />
  </BrowserRouter>
);

export default AppRouter;
