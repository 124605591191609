// --- client/scr/actions/error.js ---
export const RECEIVE_SIGNUP_ERRORS = 'RECEIVE_SIGNUP_ERRORS';
export const RECEIVE_ERRORS = 'RECEIVE_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const receiveErrors = ({ message }) => ({
    type: RECEIVE_ERRORS,
    message: message
});

export const receiveSignupErrors = ({ message }) => ({
    type: RECEIVE_SIGNUP_ERRORS,
    message: "Error with signup credentials. Username/Email not unique or password does not match requirements."
});

export const clearErrors = () => ({
    type: CLEAR_ERRORS
});