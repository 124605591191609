//import libraries
import React from 'react';
import BuilderHeader from "../../containers/builder/BuilderHeader";
import BuilderContent from "../../containers/builder/BuilderContent";
import BuilderNavigation from "../../containers/builder/BuilderNavigation";
import SocialLinksSettings from '../../components/SocialLinksSettings';
import SocialNetworksSettings from '../../components/SocialNetworksSettings';

// create a component
const SocialSettings = (props) => {
    return (
    <div className="admin__app">
    <div className="admin__container">
      <div className="admin__container--nav-build">
        <BuilderNavigation />
        <div className="admin__main-container">
          <BuilderHeader />
          <BuilderContent>
            <div><h2>SOCIAL SETTINGS</h2></div>
            <div className="flex--inline">
            <div className="flex--inline-left-2">
            <SocialLinksSettings />
            <SocialNetworksSettings />
            </div>
            <div className="flex--inline-right-2"></div>
            </div>
          </BuilderContent>
        </div>
      </div>
    </div>
  </div>
    )
};

//make this component available to the app
export default SocialSettings;
