//import libraries
import React from 'react';
import { CellPlugin } from "@react-page/editor";
import CustomDividerComponent from "./customComponents/CustomDividerComponent";

type Data = {
  title: string,
  tags: string,
  blurb: string,
  readLength: string,
  width: integer,
  height: integer,
  video: string,
};

const CustomDivider: CellPlugin<Data> = {
  Renderer: ({ data }) => (
    <CustomDividerComponent 
      name={data.name}
      color={data.color}
      height={data.height}
      width={data.width}
    />
  ),
  id: "divider2Plugin",
  title: "Custom Divider",
  description: "Add a Custom Divider",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      properties: {
        name: {
          type: "string",
          default:'fancy',
        },
        width: {
          type: "string",
          default: '100%'
        },
        height: {
          type: "string",
          default: '1'
        },
        color: {
          type: "string",
          default: 'CCCCCC'
        },
      },
    },
  },
};

export default CustomDivider;