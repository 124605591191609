//import libraries
import React from 'react';


// create a component
const Avatar = (props) => {
    return (
        <div className={`bhAvatar__root bhAvatar__color${props.bgcolor} bhAvatar__${props.type}`}>
        {props.children}
        </div>
    )
};



//make this component available to the app
export default Avatar;
