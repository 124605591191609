//--- client/src/util/session.js ---
export const signup = (user) => (
    fetch('/api/users/signup', {
        method: 'Post',
        body: JSON.stringify(user),
        headers: {
            "Content-Type": "application/json"
        }
    })
);

export const emailValidate = (pin) => (
    fetch('/api/users/emailValidate', {
        method: 'Post',
        body: JSON.stringify(pin),
        headers: {
            "Content-Type": "application/json"
        }
    })
);

export const subscribe = (subscriberInfo) => {
 return   (  fetch('/api/users/subscribe', {
        method: 'POST',
        body: JSON.stringify(subscriberInfo),
        headers: {
            "Content-Type": "application/json"
        }
    })
  )
}

export const contactForm = (contactInfo) => {
 return   (  fetch('/api/users/contact', {
        method: 'POST',
        body: JSON.stringify(contactInfo),
        headers: {
            "Content-Type": "application/json"
        }
    })
  )
}


export const login = (user) => 
(
    fetch('/api/session', {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
            "Content-Type": "application/json"
        }
    })
  )



export const logout = () => (
    fetch('/api/session', { method: 'DELETE' })
);

export const checkLoggedIn = async preloadedState => {
    const response = await fetch('/api/session');
    const { user } = await response.json();
    preloadedState = {};
    if (user) {
      preloadedState = {
        session: user
      };
    }
    return preloadedState;
  };