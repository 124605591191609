//import libraries
import React from 'react';


// create a component
const CustomAmazonAdComponent = (props) => {
    const AMZad = `<script type="text/javascript">
                        amzn_assoc_tracking_id = "61threetwenty-20";
                        amzn_assoc_ad_mode = "manual";
                        amzn_assoc_ad_type = "smart";
                        amzn_assoc_marketplace = "amazon";
                        amzn_assoc_region = "US";
                        amzn_assoc_design = "enhanced_links";
                        amzn_assoc_asins = "B08XVYZ1Y5";
                        amzn_assoc_placement = "adunit";
                        amzn_assoc_linkid = ${props.customAd};
                    </script>
                    <script src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US"></script>`;
    return (
        <div>
        { props.adType === 'custom' ? (<div dangerouslySetInnerHTML={AMZad}></div>) : (<a href={props.imageLink}><img src={props.imageUrl} title={props.title} alt={props.title} /></a>)}
            
        </div>
      )  
};

//make this component available to the app
export default CustomAmazonAdComponent;
