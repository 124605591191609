//import libraries
import React from 'react';

// create a component
const PostEntryComponent = (props) => {
    return (
        <div>
        <p>Tag Information about the Post. Hide this block.</p>
            <p><b>Blurb:</b> {props.data.blurb}</p>
            <p><b>Tags:</b> {props.data.tags}</p>
            <p><b>Read Length:</b> {props.data.readLength}</p>
            <div>
            <h3>Meta Tag Information</h3>
            <p><b>Title:</b> {props.data.metatitle}</p>
            <p><b>Description:</b> {props.data.metadescription}</p>
            <p><b>Canonical:</b> {props.data.metacanonical}</p>
            <p><b>Keywords:</b> {props.data.metakeywords}</p>
            </div>
        </div>
    )
};

//make this component available to the app
export default PostEntryComponent;
