//import libraries
import React from 'react';
import { CellPlugin } from "@react-page/editor";
import CTACustomComponent from "./customComponents/CTACustomComponent";

type Data = {
    title: string,
    image: string,
    blurb: string,
    type: string,
    location: string,
};
  
const CTABuilder: CellPlugin<Data> = {
    Renderer: ({ data }) => (
      <CTACustomComponent
        title={data.title}
        imageUrl={data.imageUrl}
        blurb = {data.blurb}
        type = {data.type}
        location = {data.location}
      />
    ),
    id: "ctaPlugin",
    title: "CTA Builder",
    description: "Add a Call to Action to the page",
    version: 1,
    controls: {
      type: "autoform",
      schema: {
        properties: {
          title: {
            type: "string",
            default: "Say What",
          },
          imageUrl: {
            type: "string",
          },
          blurb: {
            type: "string"
          },
          type: {
            type: "string"
          },
          location: {
            type: "string"
          }

        },
        required: ["title", "imageUrl"],
      },
    },
  };

  export default CTABuilder;
