//import libraries
import React from "react";
import ContentWrapper from "../components/Layout/ContentWrapper";
import BuildMetaTags from '../util/MetaTag';

// create a component
const About = (props) => {
  const metaTags = {
    metatitle: 'About',
    metadescription: '61ThreeTwenty is a design company bringing modern web design to the internet for customers.',
    metacanonical: 'about',
    metakeywords: '61ThreeTwenty, about us, programming blog, web design, security',
    image: 'https://61threetwenty.com/assets/img/61threetwenty_homepage.png',
    url: `https://61threetwenty.com/about`
  };
  return (
    <ContentWrapper>
      <BuildMetaTags metaTags={metaTags} />
      <div className="container--wrapper">
        <div><img src="/assets/img/aboutus_portpic.jpg" alt="Portfolio" /></div>
        <div className="container__aboutus">
          <h1 style={{"alignSelf":"center"}}>Welcome!</h1>
          <p>
            My name is Brad and I created this site as a way to use my experiences to teach others. 
            Selfishly I am hoping that through researching and teaching I learn the subject matters
            of this site even more. 
          </p>
          <h2>A little about my experiences</h2>
          <p>I am coming up on 20 years of professional experiences. By trade I have worked in SEO, web design
          for both desktop and mobile, IT project management, and IT security.</p>
          <p>Even with that amount of experience, I am no expert. I have so many bad habits in each of the fields I work in that it will be tough to overcome.
          If you notice any please scream, shout, yell at me about how bad of an idea it is. I will make sure to call it out and thank you.  </p>
          <p>I hope to use those experiences to write well researched and well thought out post that are engaging 
          and give insight into the subject.</p>
          <h2>Why 61ThreeTwenty</h2>
          <p>Bit of math humor there. I thought one day about making this site and its purpose. I said to no one 
          in particular, "learning should be 24/7/365". So if you take 24x7x365 you get 61320. 61ThreeTwenty.com was 
          available so here we are.</p>

          <p>
            Take a look around. Read an article or two that interest you and hopefully you learn something new. If 
            you got any questions feel free to reach out.
            </p>

          <p>
            Simply. Your time is valuable so I try to research for you and help you speed up the
            learning process.
          </p>
        </div>
      </div>
    </ContentWrapper>
  );
};

//make this component available to the app
export default About;
