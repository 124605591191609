import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FacebookProvider } from "react-facebook";
import { handleFacebookLogin } from "../../util/social/socialLogins"
import * as sessionActions from '../../actions/session';

const SocialBox = ({ visible, close, onClose, socialSites }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.session.userId);
  const userSettings = useSelector((state) => state.session.settings);
  const socialNetworks = socialSites ? socialSites.socialSites : [];
  const facebookAppId = "882324683011425"; // process.env.REACT_APP_FACEBOOK_APP_ID;

  const handleDeactivate = (token) => {
    window.FB.api(
      "/me/permissions",
      "DELETE",
      { permission: "<permission_name>" },
      function (response) {
        // handle the response
        if (response && !response.error) {
          /* handle the successful response */
        } else {
          /* handle the error */
        }
      }
    );
  };

  const handleFBLogout = () => {
    window.FB.logout(function (response) {
      // user is now logged out
    });
  };

  const handleLogin = (name) => {
    switch (name) {
      case "Facebook":
        handleFacebookLogin().then(function (result) {
          if (result) {
            dispatch(sessionActions.saveSocialToken(userId, result));  
          }
          
        });
       
        break;
      case "Twitter":
      // handleTwitterlogin();
        break;
      case "Instagram":
      //  handleInstagramlogin();
        break;
      case "Reddit":
      //  handleRedditlogin();
        break;
      case "Tumblr":
      //  handleTumblrlogin();
        break;
      case "Pinterest":
      //  handlePinterestlogin();
        break;
      case "Quora":
      //  handleQuoralogin();
        break;
      default:
        return null;
    }
  };

  function handleSuccess(response) {
  }

  function handleError(error) {
    console.log(error);
  }

  return (
    <div className={`social-box ${visible ? "visible" : "hidden"}`}>
      {close && (
        <button className="close-button" onClick={onClose}>
          X
        </button>
      )}
      {socialNetworks &&
        socialNetworks.map((network, idx) => {
          const authToken = userSettings.socialsettings.socialTokens.find(
            (o) => o.name === network.name
          );
          return (
            <div key={idx} id={idx}>
              <div className="flex--inline justify--sb">
                <div className="flex--inline">
                  <span className="w--5">
                    <i
                      className={network.icon}
                      style={{
                        color: `${network.color}`,
                        background: `${network.bgColor}`,
                        fontSize: "3.6rem",
                        padding: "2px",
                      }}
                    ></i>
                  </span>{" "}
                  <h4>{network.name}</h4>
                </div>
                <div>
                  {network.typeOfNetwork.map((item, index) => {
                    if (!authToken && network.name === "Facebook") {
                      return (
                        <FacebookProvider
                          appId={facebookAppId}
                          scope="publish_pages, manage_pages"
                          onError={handleError}
                          onSuccess={handleSuccess}
                          key={index}
                        >
                         <span className="u-margin-right-1" key={index}>
                          <button
                            className="btn__root btn__sub"
                            onClick={() => handleLogin(network.name)}
                          >
                            <span className="weight--bold link--size-medium u-margin-right-1">
                              +
                            </span>
                            {item}
                          </button>
                        </span>
                        </FacebookProvider>
                      );
                    } else if (authToken && network.name === "Facebook") {
                      return (
                        <button
                          className="btn__root btn__sub"
                          disabled={true}
                          key={index}
                        >
                          <span className="weight--bold link--size-medium u-margin-right-1">
                            +
                          </span>
                          {item} Provided
                        </button>
                      );
                    } else {
                      return (
                        <span className="u-margin-right-1" key={index}>
                          <button
                            className="btn__root btn__sub"
                            onClick={() => handleLogin(network.name)}
                          >
                            <span className="weight--bold link--size-medium u-margin-right-1">
                              +
                            </span>
                            {item}
                          </button>
                        </span>
                      );
                    }
                  })}
                </div>
              </div>
              {authToken && network.name === "Facebook" ? (
                <div
                  className="flex--inline u-margin-bottom-medium font--size-small color--grey"
                  key={`${idx}-infodiv`}
                >
                  <div>
                    <span className="weight--bold color--secondary u-margin-right-1">
                      Name:
                    </span>{" "}
                    {authToken.pageName}{" "}
                    <span className="weight--bold color--secondary u-margin-right-1">
                      Category:
                    </span>
                    {authToken.pageCategory}
                  </div>
                  <div>
                    <button
                      className="btn__link u-margin-right-1 u-margin-left-1"
                      onClick={() => handleDeactivate(authToken.token)}
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>
                    <button className="btn__link" onClick={() => handleFBLogout()}>
                      <i className="fa-solid fa-right-from-bracket"></i>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="u-margin-bottom-medium"></div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default SocialBox;
