//import libraries
import React from 'react';
import { CellPlugin } from "@react-page/editor";
import CustomVideoComponent from "./customComponents/CustomVideoComponent";

type Data = {
  title: string,
  tags: string,
  blurb: string,
  readLength: string,
  width: integer,
  height: integer,
  video: string,
};

const CustomVideo: CellPlugin<Data> = {
  Renderer: ({ data }) => (
    <CustomVideoComponent videoUrl={data.videoUrl} title={data.title} />
  ),
  id: "video2Plugin",
  title: "Video",
  description: "Embed a Video",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      properties: {
        title: {
          type: "string",
          default: "Title for Video",
        },
        videoUrl: {
          type: "string",
        },
      },
      required: ["title"],
    },
  },
};

//make this component available to the app
export default CustomVideo;
