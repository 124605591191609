import React, { useReducer, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { contact } from "../actions/session";
import Input from "../components/UI/Input";
import BuildMetaTags from '../util/MetaTag';

const FORM_INPUT_UPDATE = "FORM_INPUT_UPDATE";

const formReducer = (state, action) => {
  if (action.type === FORM_INPUT_UPDATE) {
    const updatedValues = {
      ...state.inputValues,
      [action.input]: action.value
  }
  const updatedValidities = {
      ...state.inputValidities,
      [action.input]: action.isValid
  }
  let updatedFormIsValid = true;
  for (const key in updatedValidities) {
      updatedFormIsValid = updatedFormIsValid && updatedValidities[key];
  }
  return {
      //...state, // not needed as we are updated all state items below
      formIsValid: updatedFormIsValid,
      inputValidities: updatedValidities,
      inputValues: updatedValues
  };
  }
  return state;
};

const Contact = () => {
    const dispatch = useDispatch();
    const [submitted, setSubmitted] = useState(false);
    const metaTags = {
        metatitle: 'Contact Us',
        metadescription: '61ThreeTwenty is a design company bringing modern web design to the internet for customers.',
        metacanonical: 'contact',
        metakeywords: '61ThreeTwenty, contact, contact us, web design, security',
        image: 'https://61threetwenty.com/assets/img/61threetwenty_homepage.png',
        url: `https://61threetwenty.com/contact`
        
    };
    const [formState, dispatchFormState] = useReducer(formReducer, {
        inputValues: {
            name: "",
            email: "",
            info: "",
            location:"contactusPage",
            kickoff: false,
            weekly: false
        },
        inputValidities: {
            name: true,
            email: true,
            info: true,
            location:true,
            kickoff: true,
            weekly: true
        },
        formIsValid: true,
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const contacter = {
            name: formState.inputValues.name,
            email: formState.inputValues.email,
            info: formState.inputValues.info,
            location: formState.inputValues.location,
            kickoff: formState.inputValues.kickoff,
            weekly: formState.inputValues.weekly
        }

        const action = contact(contacter);

        try {
        await dispatch(action);
        setSubmitted(true);
        } catch (err) {
        console.log("ERROR:", err.message);
        }
    };

    const inputChangeHandler = useCallback(
        (inputIdentifier, inputValue, inputValidities) => {
        
        dispatchFormState({
            type: FORM_INPUT_UPDATE,
            input: inputIdentifier,
            value: inputValue,
            isValid: true,
        });
        },
        [dispatchFormState]
    );

    return (
        <div className="contact">
        <BuildMetaTags metaTags={metaTags} />
            <div className="row">
                <div className="col-1-of-2">
                <div className="contact__topnote">
                    <span>Something on your mind?</span>
                    <h3>Contact Us</h3>
                    <p>Here to hopefully help answer any question you potentially have. </p>
                    <p>If I cannot, I will let you know that as well!</p>
                </div>
                <form className="contact__form" onSubmit={handleSubmit}> 
                    <div className="contact__form__group">
                        <Input
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            autoComplete="name"
                            autoFocus
                            onInputChange={inputChangeHandler}
                            />
                    </div>
                    
                    <div className="contact__form__group">
                         <Input
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onInputChange={inputChangeHandler}
                            />
                    </div>
                    <div className="contact__form__group">
                        <textarea placeholder="What can I do for you today?" id="info" name="info" className="contact__form__textarea" onChange={(e) => inputChangeHandler(e.target.id, e.target.value, true)}/>
                    </div>
                    <div className="contact__form__group">
                        <button className={`btn btn__sub--white--black u-margin-top-medium`}>Let's Talk &rarr;</button>
                    </div>
                </form>
                {submitted ? (<div className="contact__form_submitmessage"><h3>Thank you!</h3><p>We will reach back to you about your message soon.</p></div>) : null}
                </div>
                
            </div>
        </div>
    );
};

export default Contact;