//import libraries
import React from "react";
//import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../components/UI/Button";
import Input from "../components/UI/Input";

// create a component
const Confirmation = () => {
  const { userEmail } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
    } catch (err) {
      console.log("ERROR:", err.message);
    }
  };
  return (
    <div>
      <main>
        <div className="main-container-wrapper">
          <div className="container">
            <div className="u-margin-top-medium page-box">
              <h1>Account Confirmation</h1>
              <span>
                An email with your account confirmation pin has been sent to
                your email: <b>{userEmail}</b>
              </span>
              <p>Check your email and come back to proceed!</p>
              <form method="POST" onSubmit={handleSubmit}>
              <Input
                autoComplete="pinconfirm"
                margin="normal"
                name="pinconfirm"
                variant="outlined"
                required
                id="pinconfirm"
                label="Confirm Pin"
                autoFocus
              />
              <div>
              <Button
                type="submit"
                variant="contained"
                className="btn btn__sub u-margin-top-small"
                text="Confirm"
            /></div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

//make this component available to the app
export default Confirmation;
