//import libraries
import React from 'react';


// create a component
const BuilderContent = (props) => {
    return (
        <div className="admin-content--container">
            {props.children}
        </div>
    )
};

//make this component available to the app
export default BuilderContent;
