//--- client/src/reducers/root.js ---
import { combineReducers } from 'redux';
import errors from './errors/error';
import session from './session/session';
import tools from './tools/tools';

export default combineReducers({
    session,
    tools,
    errors
})