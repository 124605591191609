import React, { useState, useReducer, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Avatar from "../components/UI/Avatar";
import Button from "../components/UI/Button";
import Input from "../components/UI/Input";
import Checkbox from "../components/UI/Checkbox";
import FormControlLabel from "../components/UI/FormControlLabel";
import Copyright from "../components/Copyright";
import { login } from "../actions/session";

const FORM_INPUT_UPDATE = "FORM_INPUT_UPDATE";

const formReducer = (state, action) => {
  if (action.type === FORM_INPUT_UPDATE) {
    const updatedValues = {
      ...state.inputValues,
      [action.input]: action.value
  }
  const updatedValidities = {
      ...state.inputValidities,
      [action.input]: action.isValid
  }
  let updatedFormIsValid = true;
  for (const key in updatedValidities) {
      updatedFormIsValid = updatedFormIsValid && updatedValidities[key];
  }
  return {
      //...state, // not needed as we are updated all state items below
      formIsValid: updatedFormIsValid,
      inputValidities: updatedValidities,
      inputValues: updatedValues
  };
  }
  return state;
};

const Login = () => {
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      email: "",
      password: "",
      remember: false
    },
    inputValidities: {
      email: true,
      password: true,
    },
    formIsValid: true,
  });

  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    remember: false,
  });


  const handleChecked = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = {
      email: formState.inputValues.email,
      password: formState.inputValues.password,
      remember: state.remember
    }
    const action = login(user, state.remember);

    try {
      await dispatch(action);
    } catch (err) {
      console.log("ERROR:", err.message);
    }
  };

  const inputChangeHandler = useCallback(
    (inputIdentifier, inputValue, inputValidities) => {
      dispatchFormState({
        type: FORM_INPUT_UPDATE,
        input: inputIdentifier,
        value: inputValue,
        isValid: true,
      });
    },
    [dispatchFormState]
  );

  return (
      <div className="container container-Xs">
        <div className="paperTheme">
          <Avatar type="circle" bgcolor="secondary">
            <i className="fas fa-lock"></i>
          </Avatar>
          <div>
            <h5>Sign in</h5>
          </div>
          <p>{errors}</p>
          <form onSubmit={handleSubmit}>
            <Input
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onInputChange={inputChangeHandler}
            />
            <Input
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onInputChange={inputChangeHandler}
            />
            <FormControlLabel
              control={
                <Checkbox
                  className="hidebox-1"
                  name="remember"
                  value="remember"
                  checked={state.remember}
                  onChange={handleChecked}
                />
              }
              color="primary"
              checked={state.remember}    
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="btn btn__sub btn__fullWidth u-margin-top-small"
              text="Sign In"
            />
            <div className="u-margin-top-small flex__container">
              <div className="flex--xs-left">
                <Link to="#">Forgot password?</Link>
              </div>
              <div>
                <Link to="/signup">{"Don't have an account? Sign Up"}</Link>
              </div>
            </div>
              <Copyright />
          </form>
        </div>
      </div>
  );
};
//make this component available to the app
export default Login;
