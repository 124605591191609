//import libraries
import React from 'react';

// create a component
const FormInputLabel = (props) => {
    const initiallySet = props.initialValue ? props.initialValue : null;
    return (
        <label
        className={`bhformlabel__root bhinputlabel__root ${
          props.focus ? `bhinputlabel--shrink bhformlabel--focused` : ""
        } bhinputlabel--animated bhinputlabel--formControl ${
            props.fullWidth ? `bhinputlabel${props.fullWidth}` : null
        } ${props.variant ? `bhinputlabel--${props.variant}` : null} 
        ${props.size ? `bhinputlabel--size-${props.size}` : null}
        ${props.filled? `hideitem` : null}`
        
        }
      >
        {initiallySet && !props.focus ? '' : props.label }
      </label>
    )
};


//make this component available to the app
export default FormInputLabel;
