//import libraries
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Moment from 'react-moment';

// Imported Containers
import BuilderHeader from "../../containers/builder/BuilderHeader";
import BuilderContent from "../../containers/builder/BuilderContent";
import BuilderNavigation from "../../containers/builder/BuilderNavigation";

// UI Imports
import IconMark from "../../components/UI/IconMark";
import RelatedPostBuilder from "../../components/UI/RelatedPostBuilder";
import Table from "../../components/UI/Table";
import Tags from "../../components/UI/Tags";

import { SelectColumnFilter } from '../../util/Filter';
import * as toolActions from '../../actions/tools';

// create AllPostRev Component
const AllPostRev = (props) => {
  const [posts, getPosts] = useState([]);
  const userId = useSelector(state => state.session.userId);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const insidePost = await dispatch(toolActions.loadPostsAdmin(userId));
        getPosts(insidePost);
      } catch (err) { 
        console.log('error', err);
      } 
    })();
  }, [userId, dispatch]);

  const columns = useMemo(() => 
  [{
    Header: 'Post Information',
    columns: [
      {
        Header: 'Post Title',
        accessor: 'postTitle'
      },
      {
        Header: 'Content',
        accessor: 'postTags',
        Cell: ({ cell: { value } }) => <Tags values={value} />,
        // disable the filter for particular column
        disableFilters: true, 
      },
      {
        id: 'publishedPost2',
        Header: 'Status',
        accessor: 'publishedPost',
        Cell: ({ cell: { value } }) => <IconMark value={value} different="publish" />,
        disableFilters: true, 
      },
      {
        id: 'publishedPost',
        Header: 'Published',
        accessor: d => d.publishedPost.toString(),
        Filter: SelectColumnFilter,
        filter: 'includes'
      },
      {
        Header: 'Last Update',
        accessor: 'updatedAt',
        Cell: ({ cell: { value } }) => <Moment format="MM-DD-YYYY" date={value} />
      },
      {
        Header: 'Related Articles',
        accessor: 'postId',
        Cell: ({ cell: { value } }) => <RelatedPostBuilder postId={value} />,
      },
      {
        Header: 'View',
        //accessor: 'postTitleUrl',
        Cell: props => <IconMark value="postTitleUrl" different="url" postDetails={props.row.original} />,
        disableFilters: true, 
      }
    ]
  }], []);

  return (
  <div className="admin__app">
    <div className="admin__container">
      <div className="admin__container--nav-build">
        <BuilderNavigation />
        <div className="admin__main-container">
          <BuilderHeader />
          <BuilderContent>
            <Table columns={columns} data={posts} />
          </BuilderContent>
        </div>
      </div>
    </div>
  </div>
  )
};


//make this component available to the app
export default AllPostRev;
