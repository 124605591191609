//import libraries
import React from "react";

// create a component
const SearchBar = (props) => {
  return (
    <form action="/search-results/" method="get" className="header-search-wrapper">
      <label htmlFor="header-search">
        <span className="visually-hidden">Search blog posts</span>
      </label>
      <input
        type="text"
        id="header-search"
        placeholder="Search blog posts"
        className="searchBar"
        name="s"
      />
      
      <button type="submit" className="btn btn__search"><i className="fas fa-search"></i></button>
    </form>
  );
};

//make this component available to the app
export default SearchBar;
