// mail.js actions

export const sendSubscribeEmail = (subscriber) => async dispatch => {
    const data = {
        email: subscriber.email,
        subject: subscriber.subject,
        text: subscriber.text,
        html: subscriber.html
        }
    fetch(`/api/mail/subscribe`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    })
}


export const confirmSub = (code) => async dispatch => {
    const response = await fetch(`/api/mail/confirm/${code}`)
        
    return response.statusText;  
};

export const unsubscribe = (code) => async dispatch => {
    const response = await fetch(`/api/mail/unsubscribe/${code}`)
    return response.statusText;  
};