//import libraries
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import PostListItem from "../components/PostListItem";
import * as toolActions from "../actions/tools";

// create a component
const PostList = (props) => {
  const dispatch = useDispatch();
  const direction = props.direction ? props.direction : 'row';
  const [posts, getPosts] = useState([]);

  // Currently no analytics added in for most popular post
  const fetchData = useCallback(async () => {
    try {
      let postsData = [];
      const postType = props.type ? props.type : 'normal';
      if (postType === 'popular') {
        postsData = await dispatch(toolActions.loadPopularPosts());
      } else {
        postsData = await dispatch(toolActions.loadPosts());
      }
      getPosts(postsData);
    } catch (err) {
      console.log("error", err);
    }
  }, [dispatch, props.type]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  
  return (
    <div id={props.postId} className="widget widget-with-thumbs">
      <h4 className="widget-title">
        <span>{props.title}</span>
      </h4>
      <ul className={direction === 'column' ? 'widget--column' : 'widget--row'}>
      
        {
           posts.filter((item, index) => index < props.amount).map((post, index) => 
                    <PostListItem  key={post.postId} postDetails={post} />
                  )
        }
      </ul>
    </div>
  );
};

//make this component available to the app
export default PostList;
