//import libraries
import React from 'react';

// create a component
const PostContainerLayout = (props) => {
    return (
        <div className="PostContainerLayout u-margin-top-big">{props.children}</div>
    )
};


//make this component available to the app
export default PostContainerLayout;
