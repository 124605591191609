//import libraries
import React from "react";

//MuiButtonBase-root MuiIconButton-root jss9 MuiCheckbox-root MuiCheckbox-colorPrimary MuiIconButton-colorPrimary
//btn__root bhIconbtn__root bhCheckbox__colorprimary bhCheckbox-root bhIconbtn-colorprimary
// create a component
const FormControlLabel = (props) => {
  return (
    <label className="bhFormControlLabel__root">
      <span
        className={`btn__root bhCheckbox__color${props.color} ${props.checked ? `bh-checked` : null } bhCheckbox__root bhIconbtn__root bhIconbtn-color${props.color}`}
        aria-disabled="false"
      >
        <span className="bhIconbtn__label">
          {props.control}
          <svg
            className="bhSvgIcon__root"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            {props.checked ? (
              <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
            ) : (
              <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
            )}
          </svg>
        </span>
        <span className="MuiTouchRipple-root"></span>
      </span>
      <span className="bhFormControlLabel__label">{props.label}</span>
    </label>
  );
};

//make this component available to the app
export default FormControlLabel;
