//import libraries
import React from "react";

// create a component
const ContentWrapper = (props) => {
  return (
    <div>
      <main>
        <div className="main-container-wrapper">
          <div className="container">
            <div className="u-margin-top-big">{props.children}</div>
          </div>
        </div>
      </main>
    </div>
  );
};

//make this component available to the app
export default ContentWrapper;
