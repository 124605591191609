//import libraries
import React from 'react';


// create a component
const FieldSet = (props) => {

    return (
        <fieldset
          aria-hidden="true"
          className="bhinput-outlined--notchedOutline bhinput-outlined--notchedOutline-root-7"
        >
          <legend
            className={`bhformlabel-legendLabelled--9 ${
              props.focus ? `bhformlabel-legendLabelled--${props.variant}` : ""
            }`}
          >
            <span>{props.label}</span>
          </legend>
        </fieldset>
    )
};

//make this component available to the app
export default FieldSet;
