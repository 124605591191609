//import libraries
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import SocialButtonGroup from "../components/UI/SocialButtonGroup";
import SettingsCard from "../components/UI/SettingsCard";
import InfoBox from "../components/UI/InfoBox";
import * as toolActions from "../actions/tools";
import * as sessionActions from "../actions/session";

// create a component
const SocialLinksSettings = (props) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.session.userId);
  //const userSettings = useSelector((state) => state.session.settings);
  const [socialSites, setSocialSites] = useState(null);
  const [userPreferences, setUserPreferences] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [infoBoxVisible, setInfoBoxVisible] = useState(false);

  const toggleInfoBox = () => {
    setInfoBoxVisible(!infoBoxVisible);
  };

  const hideInfoBox = () => {
    setInfoBoxVisible(false);
  };

  // Currently no analytics added in for most popular post
  const getSocialDefaultSettings = useCallback(async () => {
    try {
      let linkInfo = {};
      linkInfo = await dispatch(toolActions.loadSocialSites());
      setSocialSites({ socialSites: linkInfo });
    } catch (err) {
      console.log("error", err);
    }
  }, [dispatch]);

  const checkUserPreferences = useCallback(async () => {
    try {
      let userPrefs = {};
      userPrefs = await dispatch(
        sessionActions.checkUserSocialSettings(userId)
      );
      setUserPreferences(userPrefs);
    } catch (err) {
      console.log("error", err);
    }
  }, [dispatch, userId]);

  useEffect(() => {
    getSocialDefaultSettings();

    //    const alreadySet = userSettings ? userSettings.socialsettings.socialSites : null
    // if(!alreadySet) {
    checkUserPreferences();
    //}
  }, [getSocialDefaultSettings, checkUserPreferences]);

  const onChangeHandler = (state) => {
    setUserPreferences(state);
  };

  const onSaveHandler = async () => {
    setIsSaving(true);
    await dispatch(
      sessionActions.saveSocialPreferences(userId, userPreferences)
    );
    setTimeout(() => setIsSaving(false), 2000);
  };
  return (
    <div>
      <div>
        <SettingsCard>
          <div className="flex--inline justify--sb">
            <div className="flex--inline justify--sb">
              <h3>Social Follow Link Buttons</h3>
              <button
                className="txt-btn u-margin-left-1 link--size-small"
                onClick={toggleInfoBox}
              >
                Show Info
              </button>
              <InfoBox
                visible={infoBoxVisible}
                close={true}
                onClose={hideInfoBox}
              >
                <h1>Social Share Information</h1>
                <p className="paragraph--note-size">
                  Social Sharing buttons allow you to have social links on each
                  of your blog post. Make sure to include the link to your page
                  on the social network you want to share. Currently we only
                  allow one page per network.
                </p>
              </InfoBox>
            </div>
            <button
              className={
                `btn__root btn__sub` + (isSaving ? " btn__disabled" : "")
              }
              onClick={onSaveHandler}
              disabled={isSaving}
            >
              {isSaving ? "Saving..." : "Save Links"}
            </button>
          </div>

          <div className="admin__container-bch--controls">
            {!socialSites ? (
              <h3>Loading...</h3>
            ) : (
              
              <SocialButtonGroup
                socialSites={socialSites}
                userPreferences={userPreferences}
                onChangeHandler={onChangeHandler}
              />
            )}
          </div>
        </SettingsCard>
      </div>
    </div>
  );
};

//make this component available to the app
export default SocialLinksSettings;
