//import libraries
import React from 'react';


// create a component
const CustomImageComponent = (props) => {

    return (
    <div>
      <img src={props.imageUrl} title={props.title} width={props.width} alt={props.title} />
      {props.imageCaption !== '' && (
        <figcaption>{props.figcaption}</figcaption>
      )}
    </div>
    )
};

//make this component available to the app
export default CustomImageComponent;
