//import libraries
import React from 'react';


// create a component
const CustomCaptionComponent = (props) => {

    return (
    <div className="caption">
      <p>{props.text} <a href={props.linkUrl}>{props.linkText}</a></p>
    </div>
    )
};

//make this component available to the app
export default CustomCaptionComponent;
