//import libraries
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import BuilderHeader from "../../containers/builder/BuilderHeader";
import BuilderContent from "../../containers/builder/BuilderContent";
import BuilderNavigation from "../../containers/builder/BuilderNavigation";
import SocialListItem from "../../components/SocialListItem";
import * as toolActions from "../../actions/tools";

// create a component
const SocialPostBuilder = (props) => {
  const dispatch = useDispatch();
  //const userId = useSelector((state) => state.session.userId);
  const userSettings = useSelector((state) => state.session.settings);
  const [socialSites, setSocialSites] = useState(null);
  const [activeIndices, setActiveIndices] = useState(new Set());

  const getSocialDefaultSettings = useCallback(async () => {
    try {
      let linkInfo = {};
      linkInfo = await dispatch(toolActions.loadSocialSites());
      console.log("linkInfo: ", linkInfo);
      setSocialSites({ socialSites: linkInfo });
    } catch (err) {
      console.log("error", err);
    }
  }, [dispatch]);

  useEffect(() => {
    getSocialDefaultSettings();
  }, [getSocialDefaultSettings]);

  const handleAddToSocialPost = (index) => {
    console.log("activeIndices: ", activeIndices);
    const newIndices = new Set(activeIndices);
    if (activeIndices.has(index)) {
      newIndices.delete(index);
    } else {
      newIndices.add(index);
    }
    setActiveIndices(newIndices);
  };

  return (
    <div className="admin__app">
      <div className="admin__container">
        <div className="admin__container--nav-build">
          <BuilderNavigation />
          <div className="admin__main-container">
            <BuilderHeader />
            <BuilderContent>
              <div className="flex--inline">
                <div className="flex--inline-left-2">
                  <h1>Social Post Settings</h1>
                  {socialSites &&
                    userSettings.socialsettings.socialTokens &&
                    userSettings.socialsettings.socialTokens.map(
                      (acct, index) => {
                        const socialSite = socialSites.socialSites.find(
                          (o) => o.name === acct.name
                        );
                        console.log('acct: ', acct)
                        return (
                          <div id={index} key={index}>
                            {activeIndices.has(index) ? (
                              <div className="flex-box--column">
                              <div className="flex--inline">
                                <span className="w--5">
                                  <i
                                    className={socialSite.icon}
                                    style={{
                                      color: `${socialSite.color}`,
                                      background: `${socialSite.bgColor}`,
                                      fontSize: "3.6rem",
                                      padding: "2px",
                                    }}
                                  ></i>
                                </span>
                                <div><span className="weight--bold u-margin-right-1">Page: </span></div>{acct.pageName}
                              </div>
                              <div>
                                <form>
                                  <textarea className="social-textarea">Hello there, this is some text in a text area</textarea>
                                </form>
                              </div>
                              </div>
                            ) : null}

                          </div>
                        );
                      }
                    )}
                </div>
                <div className="flex--inline-right-2x bg--color-black_shade">
                  <div className="flex--inline padd-a--lar border-bottom--black">
                    <span className="color--secondary u-margin-right-1">
                      <i className="fa-solid fa-user"></i>
                    </span>
                    <h3 className="color--white">Current Social Accounts</h3>
                  </div>
                  <div className="border-top--grey">
                    <div className="flex--inline padd-a--lar alignment-items--center">
                      <h3 className="padd-a--lar">
                        <i className="fa-solid fa-plus"></i>
                      </h3>
                      <div>
                        <span className="color--white">ADD MORE...</span>
                        <div>
                          <button className="btn btn__link font--size-small">
                            Profile
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      {socialSites &&
                        userSettings.socialsettings.socialTokens &&
                        userSettings.socialsettings.socialTokens.map(
                          (acct, index) => {
                            const socialSite = socialSites.socialSites.find(
                              (o) => o.name === acct.name
                            );
                            return (
                              <SocialListItem
                                onClick={() => handleAddToSocialPost(index)}
                                activeIndices={activeIndices}
                                itemKey={index}
                                key={index}
                                socialSite={socialSite}
                                acct={acct}
                              />
                            );
                          }
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </BuilderContent>
          </div>
        </div>
      </div>
    </div>
  );
};

//make this component available to the app
export default SocialPostBuilder;
