//import libraries
import React from 'react';
import { CellPlugin } from "@react-page/editor";
import CustomImageComponent from "./customComponents/CustomImageComponent";

type Data = {
  title: string,
  tags: string,
  blurb: string,
  readLength: string,
  width: integer,
  height: integer,
  video: string,
};

const CustomImage: CellPlugin<Data> = {
  Renderer: ({ data }) => (
    <CustomImageComponent
      imageUrl={data.imageUrl}
      title={data.title}
      figcaption = {data.figcaption}
      height={data.height}
      width={data.width}
    />
  ),
  id: "image2Plugin",
  title: "Image",
  description: "Add an Image to the page",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      properties: {
        title: {
          type: "string",
          default: "Welcome to 61ThreeTwenty.com", 
        },
        imageUrl: {
          type: "string",
          default:'/assets/img/default.png'
        },
        figcaption: {
          type: "string"
        },
        width: {
          type: "string",
          default: "100%",
        },
      },
      required: ["title", "imageUrl"],
    },
  },
};

export default CustomImage;