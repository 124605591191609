import React, { useState, useEffect, useCallback } from "react";
import Features from "../containers/Features";
import HomeContent from "../containers/HomeContent";
import Modal from "../components/UI/Modal";
import Cookies from 'universal-cookie';

const Main = ({ t, i18n }) => {
  const cookies = new Cookies();
  const [modalShow, setModalShow] = useState(false);
   
  const Toggle = useCallback(() => {
    setModalShow(!modalShow);
  }, [modalShow]);

  useEffect(() => {
    if(!cookies.get('subscribed61320')){
      setTimeout(() => setModalShow(true), 5000);
    }
  }, [cookies]);

  useEffect(() => {
    if(modalShow) {
            document.body.style.overflow = "hidden"; 
    } else {
            document.body.style.overflow = "visible"; 
    }
  }, [modalShow, Toggle])

  return (
    <main>
      <Modal show={modalShow} close={Toggle} form="true" thruType="modal" loc="Homepage" />
      <div className="main-container-wrapper">
        <Features />
        <HomeContent postClick={false} />
      </div>
    </main>
  );
};

export default Main;
