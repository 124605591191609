//import libraries
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import FeatureItem from "../components/FeatureItem";
import * as toolActions from "../actions/tools";

// create a component
const Features = (props) => {
  const dispatch = useDispatch();
  const [posts, getPosts] = useState([]);
  const fetchData = useCallback(async () => {
    try {
      const postsData = await dispatch(toolActions.loadPosts());
      getPosts(postsData);
    } catch (err) {
      console.log("error", err);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="feature-box-container">
      <div className="feature-box">
        <ul>
          {
                  posts.filter((item, index) => index < 4).map((post, index) => 
                    <FeatureItem number={index + 1} key={post.postId} postDetails={post} />
                  )
                }
        </ul>
      </div>
    </div>
  );
};

//make this component available to the app
export default Features;
