import React from "react";
import { Helmet } from 'react-helmet';

const BuildMetaTags = (props) => {
    const tags = props.metaTags ? props.metaTags : [];
    const meta = {
        title: `${tags.metatitle}`,
        description: `${tags.metadescription}`,
        canonical: `${tags.metacanonical}`,
        meta: {
            charset: "utf-8",
            name: {
            keywords: `${tags.metakeywords}`
            }
        },
        image: `${tags.imageUrl}`,
        url: `${tags.url}`
    };

  return (
    <div>
      <Helmet>
        <title>61ThreeTwenty - {meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.description} />
        <meta property="og:url" content={meta.url} />
        <meta name="og:image" content={meta.image} />
        <meta name="keywords" content={meta.meta.name.keywords} />
        <meta http-equiv="Content-Security-Policy" content="default-src 'none';
              base-uri 'self';
              manifest-src 'self';
              form-action 'self';
              frame-src 'self' data: https://giphy.com/;
              connect-src 'self' https://ka-f.fontawesome.com https://www.google-analytics.com;
              script-src 'self' 'unsafe-inline' 'unsafe-eval' https://kit.fontawesome.com https://www.googletagmanager.com https://connect.facebook.net/ https://storage.googleapis.com;
              img-src 'self' data: https://1.envato.market https://nordvpn.sjv.io https://www.amazon.com https://namecheap.pxf.io https://a.impactradius-go.com https://bluehost.sjv.io https://imp.pxf.io https://www.ojrq.net https://nordvpn.sjv.io https://giphy.com/ https://www.facebook.com https://www.googletagmanager.com https://a.impactradius-go.com;
              font-src 'self' data: https://ka-f.fontawesome.com https://fonts.gstatic.com https://fonts.gstatic.com;
              style-src 'self' 'unsafe-inline' https://ka-f.fontawesome.com https://fonts.gstatic.com https://fonts.googleapis.com;" 
        />
        <meta name="twitter:title" content={meta.title} /> 
        <meta name="twitter:url" content={meta.url} />
        <meta name="twitter:image" content={meta.image} />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href={`https://61threetwenty.com/${meta.canonical}`} />
      </Helmet>
    </div>
  );
}

//make this component available to the app
export default BuildMetaTags;