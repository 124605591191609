import React from 'react';
import { CellPlugin } from "@react-page/editor";
import TopChoiceComponent from "./customComponents/TopChoiceComponent";

type Data = {
  title: string,
  tags: string,
  blurb: string,
  readLength: string,
  width: integer,
  height: integer,
  video: string,
};

const TopChoiceBlock: CellPlugin<Data> = {
  Renderer: ({ data }) => (
    <TopChoiceComponent
      bestFor={data.bestFor}
      productImageUrl={data.productImageUrl}
      title = {data.title}
      paragraph={data.paragraph}
      siteUrl={data.siteUrl}
      reviewAnchor={data.reviewAnchor}
    />
  ),
  id: "topChoiceBlock",
  title: "Top Choice",
  description: "Block to use for review pages",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      properties: {
        bestFor: {
          type: "string",
        },
        productImageUrl: {
          type: "string",
        },
        title: {
          type: "string"
        },
        paragraph: {
          type: "string",
        },
        siteUrl: {
          type: "string"
        },
        reviewAnchor: {
          type: 'string'
        }
      },
    },
  },
};

export default TopChoiceBlock;