import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { loadTopics } from "../../actions/tools";

const Navigation = () => {
  const [isSecondNavVisible, setIsSecondNavVisible] = useState(false);
  const [topics, getTopics] = useState([]);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadTopics())
      .then((data) => {
        data.map((el, index) => {
          return getTopics(data.filter((item) => {
            return (item.publishedCount > 0)
          }))
        })
      })
      .catch((error) => console.log(error));
  }, [isSecondNavVisible, dispatch]);

  const toggleNav = () => {
    setIsSecondNavVisible(!isSecondNavVisible);
  };

  const toggleHam = (e) => {
    setMobileNavOpen(!mobileNavOpen);
    if (isSecondNavVisible) {
      setIsSecondNavVisible(!isSecondNavVisible);
    }
  };

  return (
    <>
      {true && (
        <nav className="site-nav">
          <ul className={`site-nav__list ${mobileNavOpen ? "active" : ""}`}>
            <li className="site-nav__item">
              <NavLink
                to="/"
                className="site-nav__link link--color link--size-medium"
                onClick={toggleHam}
              >
                Home
              </NavLink>
            </li>
            <li className="site-nav__item" onClick={toggleNav}>
              <span className="site-nav__link link--color link--size-medium">
                Topics{" "}
                <i
                  className={
                    isSecondNavVisible
                      ? "fa fa-chevron-up"
                      : "fa fa-chevron-down"
                  }
                ></i>
              </span>
              {isSecondNavVisible && (
                <ul className="site-nav__item--subnav">
                  {topics.map((topic, index) => {
                    const topicnospaces = topic.name.replace(" ", "");
                    const urlstring = `/categories/${topicnospaces}/`;
                    const name = topic.name;
                    return (
                      <li key={index}>
                        <NavLink
                          to={{
                            pathname: `${urlstring}`,
                            state: {
                              item: { topicnospaces },
                              name: { name },
                            },
                          }}
                          className="link--size-small site-nav__link"
                          onClick={toggleHam}
                        >
                          {topic.name}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
            <li className="site-nav__item">
              <NavLink
                to="/contact"
                className="site-nav__link link--color link--size-medium"
                onClick={toggleHam}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>
      )}
      <div
        href="#"
        onClick={toggleHam}
        className={`site-nav__hamburger ${mobileNavOpen ? "active" : ""}`}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </>
  );
};

export default Navigation;