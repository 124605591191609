import React from "react";

const AdPlaced = (props) => {
    const imgHeight = props.imgHeight ? props.imgHeight : 0;
    const imgWidth = props.imgWidth ? props.imgWidth : 0;
  return (
    <div className="adPlaced u-margin-top-medium u-margin-bottom-medium">
      <a
        href={props.source}
        target="_top"
        id={props.id}
      >
        <img
          src={props.imagelink}
          border="0"
          alt="Adlink"
          height={imgHeight}
          width={imgWidth}
        />
      </a>
      <img
        height="0"
        width="0"
        alt="hidden"
        src={props.imghlink}
        className="hai"
        border="0"
      />
    </div>
  );
};

export default AdPlaced;
