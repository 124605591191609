import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import Navigation from "../components/Nav/Navigation";
import SearchBar from "../components/SearchBar";
import { logout } from "../actions/session";
import BuildMetaTags from '../util/MetaTag';

const Header = () => {
  const loggedIn = useSelector((state) => Boolean(state.session.userId));
  const userName = useSelector((state) => state.session.username);
  const email = useSelector((state) => state.session.email);
  const initialMeta = {
    metatitle: 'Initial Title',
    metadescription: '61ThreeTwenty is a design company bringing modern web design to the internet for customers.',
    metacanonical: '61threetwenty.com',
    metakeywords: 'initial keywords'
  }
  const [showSubNav, setShowSubNav] = useState(false);
  const firstLetter = userName ? userName.charAt(0).toUpperCase() : "";

  const handleSubNav = () => {
    setShowSubNav(!showSubNav);
  };

  const dispatch = useDispatch();

  const handleLogout = async (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  return (
    <header className="header">
      <div className="header-wrapper">
        <div className="header-wrapper-main-nav-wrapper">
          <div className="header-wrapper--combiner">
            <div className="header-wrapper--logo">
            <BuildMetaTags metaTags={initialMeta} />
              <NavLink to="/">
                61<span>:</span>ThreeTwenty
              </NavLink>
            </div>
            <Navigation />
            <SearchBar />
          </div>
          {loggedIn ? (
            <div className="site-nav__acct">
              <div className="site-nav__acct--btn" onClick={handleSubNav}>
                <span>{firstLetter}</span>
              </div>
              {showSubNav ? (
                <div className="site-nav__acct--subnav bf-ld">
                  {/* todo: add top level info on person */}
                  <div className="site-nav__acct--subnav--header">
                    <div
                      className="site-nav__acct--btn marg-r--x"
                      
                    >
                      <span>{firstLetter}</span>
                    </div>{" "}
                    <div className="site-nav__acct--subnav--header--username">
                      {" "}
                      <span>{userName}</span> <span>{email}</span>
                    </div>
                  </div>
                  <div className="site-nav__acct--subnav--subset">
                    <Link
                      className="site-nav__acct--subnav-link"
                      to="/builder/dashboard"
                    >
                      Dashboard
                    </Link>
                    <Link
                      className="site-nav__acct--subnav-link"
                      to="/builder/create"
                    >
                      Write a post
                    </Link>
                    <Link
                      className="site-nav__acct--subnav-link"
                      to="/builder/allpost"
                    >
                      All Post
                    </Link>
                    <Link className="site-nav__acct--subnav-link" to="/profile">
                      Profile
                    </Link>
                    <Link
                      className="site-nav__acct--subnav-link"
                      to="/builder/settings"
                    >
                      Settings
                    </Link>
                  </div>
                  <div className="site-nav__acct--subnav--subset">
                    <Link className="site-nav__acct--subnav-link" to="/help">
                      Help
                    </Link>
                    <Link
                      className="site-nav__acct--subnav-link"
                      to="/login"
                      onClick={handleLogout}
                    >
                      Sign out
                    </Link>
                  </div>
                  <div className="site-nav__acct--subnav--footer">
                    <Link className="site-nav__acct--subnav-link" to="/privacy">
                      Privacy
                    </Link>
                    <Link className="site-nav__acct--subnav-link" to="/terms">
                      Terms
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <div>
              <ul>
              <li></li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="progress-bar"></div>
    </header>
  );
};

export default Header;
