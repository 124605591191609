//import libraries
import React from 'react';
import BuilderHeader from "../../containers/builder/BuilderHeader";
import BuilderContent from "../../containers/builder/BuilderContent";
import BuilderNavigation from "../../containers/builder/BuilderNavigation";
// create a component
const PagesList = (props) => {
    return (
        <div className="admin__app">
      <div className="admin__container">
        <div className="admin__container--nav-build">
          <BuilderNavigation />
          <div className="admin__main-container">
            <BuilderHeader />
            <BuilderContent>
              <div>PAGES</div>
            </BuilderContent>
          </div>
        </div>
      </div>
    </div>
    )
};



//make this component available to the app
export default PagesList;
