//--- client/src/reducers/session/session.js ---
import {
    RECEIVE_CURRENT_USER,
    LOGOUT_CURRENT_USER,
    RECEIVE_USER_SETTINGS,
} from '../../actions/session';

const _nullSession = { userId: null, username: null, settings: null }

export default (state = _nullSession, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_CURRENT_USER:
            return action.user;
        case LOGOUT_CURRENT_USER:
            return _nullSession;
        case RECEIVE_USER_SETTINGS:
            return {
                ...state,
                settings: action.settings
        };
        default: 
            return state;
    }
};