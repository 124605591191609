//import libraries
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../actions/session";
import { setToggle } from "../../actions/tools";

// create a component
const BuilderHeader = () => {
  const userName = useSelector((state) => state.session.username);
  const [showSubNav, setShowSubNav] = useState(false);
  const firstLetter = userName ? userName.charAt(0).toUpperCase() : '';
  const email = useSelector((state) => state.session.email);
  const toggleCheck = useSelector((state) => state.tools.toggle);
  const dispatch = useDispatch();

  const [toggleNav, setToggleNav] = useState(toggleCheck);//useSelector((state) => state.tools.toggle);// useState(false);
  const handleShowNav = () => {
    setToggleNav(!toggleNav);
    dispatch(setToggle(toggleNav));
  };
  const handleLogout = async (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  const handleSubNav = () => {
    setShowSubNav(!showSubNav);
  };

  useEffect(() => {
    const sideNav = document.getElementsByClassName(
      "admin-navigation__sidebar"
    );
    const sideLogo = document.getElementsByClassName(
        "header-wrapper--logo-dash"
      );
    const sideTitles = document.getElementsByClassName(
        "admin-list__menu--title"
      );
      const sidePadding = document.getElementsByClassName(
        "admin-navigation__sidebar--content"
      );
      
    if (sideNav.length > 0) {
      if (toggleNav) {
        sidePadding[0].style['paddingTop'] = '64px';
        sideNav[0].style.width = "6rem";
        sideLogo[0].style.display = 'none';
        for (var i = 0; i < sideTitles.length; i++ ) {
            sideTitles[i].style.display = "none";
        }
      } else {
        sidePadding[0].style['paddingTop'] = '0';
        sideNav[0].style.width = "25rem";
        sideLogo[0].style.display = 'inherit';
        for (var j = 0; j < sideTitles.length; j++ ) {
            sideTitles[j].style.display = "inherit";
        }
      }
    }
  }, [toggleNav]);

  return (
    <div className="admin-header__container">
      <header className="admin-header__root">
        <div className="admin-header__toolbar">
          <div className="flex--inline alignment-items--center sp--btw w--100">
            <ul className="admin-header__toolbar--left">
              <li
                className="admin-header__toolbar--lii br--huge"
                onClick={handleShowNav}
              >
                <i className="fas fa-bars"></i>
              </li>
              <li className="admin-header__toolbar--lii br--small">
                <i className="fas fa-th"></i>
              </li>
            </ul>
            <ul className="admin-header__toolbar--right">
              <li className="admin-header__toolbar--lii">
              <div className="site-nav__acct--btn" onClick={handleSubNav} style={{'marginRight':'0', 'padding':'2px 12px'}}>
              <span>{firstLetter}</span>
            </div>
              
              {showSubNav ? (
                <div className="site-nav__acct--subnav bf-sm">
                  {/* todo: add top level info on person */}
                  <div className="site-nav__acct--subnav--header">
                    <div
                      className="site-nav__acct--btn marg-r--med"
                    >
                      <span>{firstLetter}</span>
                    </div>{" "}
                    <div className="site-nav__acct--subnav--header--username">
                      {" "}
                      <span>{userName}</span> <span>{email}</span>
                    </div>
                  </div>
                  <div className="site-nav__acct--subnav--subset">
                    {/* <Link
                      className="site-nav__acct--subnav-link"
                      to="/contributor"
                    >
                      Become a contributor
                    </Link> */}
                    <Link
                      className="site-nav__acct--subnav-link"
                      to="/settings"
                    >
                      Settings
                    </Link>
                    <Link className="site-nav__acct--subnav-link" to="/help">
                      Help
                    </Link>
                    <Link
                      className="site-nav__acct--subnav-link"
                      to="/login"
                      onClick={handleLogout}
                    >
                      Sign out
                    </Link>
                  </div>
                  <div className="site-nav__acct--subnav--footer">
                    <Link className="site-nav__acct--subnav-link" to="/privacy">
                      Privacy
                    </Link>
                    <Link className="site-nav__acct--subnav-link" to="/terms">
                      Terms
                    </Link>
                  </div>
                </div>
              ) : null}
              </li>
            </ul>
          </div>
        </div>
      </header>
    </div>
  );
};

//make this component available to the app
export default BuilderHeader;
