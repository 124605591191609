//import libraries
import React from "react";
import { YoutubePlayer } from "reactjs-media";

// create a component
const CustomVideoComponent = (props) => {
  return (
    <div>
      <YoutubePlayer
        src={props.videoUrl ? props.videoUrl : ''}
        width={650}
        height={600}
      />
    </div>
  );
};

//make this component available to the app
export default CustomVideoComponent;
