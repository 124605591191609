//import libraries
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import Pagination from '../components/UI/Pagination';
import PostFeature from "../components/PostFeature";
import PostStory from "../components/PostStory";
import SideBar from "./SideBar";
import { initGA } from "../util/ga-utils";
import * as toolActions from "../actions/tools";
import BuildMetaTags from '../util/MetaTag';
import AdPlaced from "../components/AdPlaced";

let PageSize = 10;

// create a component
const Content = (props) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const [posts, getPosts] = useState([]);

  const metaTags = {
    metatitle: 'Homepage',
    metadescription: '61ThreeTwenty is a design company bringing modern web design to the internet for customers.',
    metacanonical: '',
    metakeywords: 'home',
    image: 'https://61threetwenty.com/assets/img/61threetwenty_homepage.png',
    url: `https://61threetwenty.com/`
    
};
  const fetchData = useCallback(async () => {
    try {
      const postsData = await dispatch(toolActions.loadPosts());
      getPosts(postsData);
    } catch (err) {
      console.log("error", err);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return posts.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, posts]);

  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  return (
    <div id="main-container">
    <BuildMetaTags metaTags={metaTags} />
      {/*postClick allows for us to reuse content for features as well as post*/}
      {props.postClick ? (
        <PostStory />
      ) : (
        <div className="container sidebar-open clearfix list-container">
          <div id="content">
            <div className="post-list list">
              <div className="list-layout">
                <h1>The Latest</h1>
                {/* This will be a loop to create the features of the content home
                using the map condition*/}
                {currentTableData.length ? (
                  currentTableData.map((post, index) => {
                    if (index === 3)  {
                      return (
                        <>
                          <PostFeature key={post.postId} postDetails={post} />
                          <AdPlaced source="https://bluehost.sjv.io/c/3614391/1465851/11352" 
                            imagelink="//a.impactradius-go.com/display-ad/11352-1465851" 
                            imghlink="https://imp.pxf.io/i/3614391/1465851/11352"
                            id="1465851" 
                            imgWidth="760"
                            imgHeight="80"
                          />
                        </>
                      )
                      
                    } else {
                      return <PostFeature key={post.postId} postDetails={post} />
                    }

                    
                  })
                ) : (
                  <div>COMING SOON</div>
                )}
              </div>
            </div>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={posts.length}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)}
            />
          </div>
            <SideBar />
        </div>
      )}
      <CookieConsent
       // disableStyles={true}
        //enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        buttonClasses="btn btn__sub"
        buttonText="Accept"
        buttonStyle={{ color: "white", backgroundColor: "#0076BF", borderRadius: ".4rem", padding: "1rem 1.5rem" }}
        //containerClasses="container--cookie"
        // contentClasses="text-capitalize"
      >
        We use cookies to provide, improve, and localize the content we serve. By using our site, you consent to cookies. Note: Currently the only cookies we serve are google analytics.
      </CookieConsent>
    </div>
  );
};

//make this component available to the app
export default Content;
